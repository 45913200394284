import React from 'react'

const Notes = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.8796 13.9207L27.5729 19.494C26.4529 24.3073 24.2396 26.254 20.0796 25.854C19.3513 25.7905 18.6291 25.6701 17.9196 25.494L15.6796 24.9607C10.1196 23.6407 8.39959 20.894 9.70626 15.3207L11.0129 9.734C11.2321 8.72268 11.563 7.73887 11.9996 6.80066C13.5583 3.57399 16.2129 2.70733 20.6663 3.76066L22.8929 4.28066C28.4796 5.58733 30.1863 8.34733 28.8796 13.9207Z" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M20.0805 25.8541C19.1076 26.4766 18.0531 26.9612 16.9471 27.2941L14.8405 27.9875C9.54712 29.6941 6.76046 28.2675 5.04046 22.9741L3.33379 17.7075C1.62712 12.4141 3.04045 9.61412 8.33379 7.90745L10.4405 7.21411C10.9518 7.04565 11.4728 6.90763 12.0005 6.80078C11.5639 7.73898 11.233 8.7228 11.0138 9.73412L9.70712 15.3208C8.40046 20.8941 10.1205 23.6408 15.6805 24.9608L17.9205 25.4941C18.63 25.6703 19.3522 25.7906 20.0805 25.8541Z" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M16.8535 11.373L23.3202 13.013" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.5469 16.5332L19.4135 17.5199" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default Notes