import React from 'react'
import future from './future.png'

const FuturePic = ({ className }: { className?: string }) => {
  return (
  
        <img className={className} src={future} alt="" />
    
  )
}




export default FuturePic