import React from 'react'

const Code = () => {
  return (
    <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.09261 17.0613L8.34591 17.808C8.24635 17.9075 8.13185 17.9573 8.00242 17.9573C7.87299 17.9573 7.7585 17.9075 7.65894 17.808L0.699634 10.8487C0.600073 10.7491 0.550293 10.6346 0.550293 10.5052C0.550293 10.3758 0.600073 10.2613 0.699634 10.1617L7.65894 3.20241C7.7585 3.10285 7.87299 3.05307 8.00242 3.05307C8.13185 3.05307 8.24635 3.10285 8.34591 3.20241L9.09261 3.94911C9.19217 4.04868 9.24195 4.16317 9.24195 4.2926C9.24195 4.42203 9.19217 4.53652 9.09261 4.63608L3.2235 10.5052L9.09261 16.3743C9.19217 16.4739 9.24195 16.5884 9.24195 16.7178C9.24195 16.8472 9.19217 16.9617 9.09261 17.0613ZM17.9187 1.12657L12.3483 20.4065C12.3084 20.5359 12.2313 20.633 12.1168 20.6977C12.0023 20.7624 11.8853 20.7749 11.7658 20.7351L10.8399 20.4812C10.7105 20.4414 10.6134 20.3642 10.5487 20.2497C10.484 20.1352 10.4715 20.0133 10.5114 19.8838L16.0818 0.603871C16.1216 0.474442 16.1988 0.37737 16.3133 0.312656C16.4278 0.247941 16.5447 0.235496 16.6642 0.27532L17.5901 0.5292C17.7196 0.569025 17.8166 0.646184 17.8813 0.760679C17.9461 0.875174 17.9585 0.997136 17.9187 1.12657ZM27.7304 10.8487L20.7711 17.808C20.6715 17.9075 20.557 17.9573 20.4276 17.9573C20.2982 17.9573 20.1837 17.9075 20.0841 17.808L19.3374 17.0613C19.2379 16.9617 19.1881 16.8472 19.1881 16.7178C19.1881 16.5884 19.2379 16.4739 19.3374 16.3743L25.2065 10.5052L19.3374 4.63608C19.2379 4.53652 19.1881 4.42203 19.1881 4.2926C19.1881 4.16317 19.2379 4.04868 19.3374 3.94911L20.0841 3.20241C20.1837 3.10285 20.2982 3.05307 20.4276 3.05307C20.557 3.05307 20.6715 3.10285 20.7711 3.20241L27.7304 10.1617C27.83 10.2613 27.8797 10.3758 27.8797 10.5052C27.8797 10.6346 27.83 10.7491 27.7304 10.8487Z" fill="#333333"/>
</svg>

  )
}

export default Code