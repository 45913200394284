const TelegramIco = ({className}: { className?: string }) => {
    return (
        <svg className={className} width="49" height="48" viewBox="0 0 49 48" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.3333 0C11.0784 0 0.333252 10.7451 0.333252 24C0.333252 37.2549 11.0784 48 24.3333 48C37.5881 48 48.3333 37.2549 48.3333 24C48.3333 10.7451 37.5881 0 24.3333 0ZM35.4576 16.3219C35.0965 20.1169 33.5337 29.3263 32.7383 33.5768C32.4019 35.3752 31.7394 35.9783 31.0982 36.0373C29.7046 36.1656 28.6461 35.1163 27.2962 34.2314C25.1839 32.8467 23.9906 31.9847 21.9401 30.6336C19.5706 29.0721 21.1067 28.2142 22.4571 26.811C22.8105 26.4439 28.9513 20.8584 29.0702 20.3517C29.085 20.2883 29.0992 20.0517 28.9585 19.9275C28.8178 19.8032 28.6111 19.8453 28.4615 19.8791C28.2497 19.9272 24.8753 22.1576 18.3382 26.5702C17.3804 27.2279 16.5129 27.5484 15.7355 27.5316C14.8786 27.5131 13.2303 27.0471 12.0048 26.6487C10.5018 26.1602 9.30722 25.9019 9.41125 25.0722C9.46545 24.6399 10.0606 24.198 11.1967 23.7464C18.1933 20.6981 22.8587 18.6884 25.193 17.7173C31.858 14.945 33.243 14.4635 34.1457 14.4474C34.3442 14.4441 34.7882 14.4933 35.0757 14.7266C35.2669 14.8928 35.3888 15.1248 35.4173 15.3765C35.466 15.6892 35.4795 16.0063 35.4576 16.3219Z"
                fill="black"/>
        </svg>

    )
}

export default TelegramIco