import React from 'react'

const Tick = ({ className }: { className?: string }) => {
    return (
        <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 0C10.7423 0 0 10.7423 0 24C0 37.2577 10.7423 48 24 48C37.2577 48 48 37.2577 48 24C48 10.7423 37.2577 0 24 0ZM20.2962 32.5038C20.0192 32.7808 19.6269 33.0115 19.2808 33.0115C18.9346 33.0115 18.5423 32.7692 18.2538 32.4923L11.7923 26.0308L13.8462 23.9769L19.2923 29.4231L33.6923 14.9192L35.7115 17.0077L20.2962 32.5038Z" fill="white" />
        </svg>
    )
}

export default Tick