import React from 'react'

const GrowSkillsIcon = ({className, color}: { className?: string, color?: string }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 61 61" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M43.5715 17.4286V52.2857C43.5715 52.8635 43.8011 53.4176 44.2096 53.8262C44.6182 54.2348 45.1723 54.4643 45.7501 54.4643H54.4644C55.0422 54.4643 55.5963 54.2348 56.0049 53.8262C56.4134 53.4176 56.643 52.8635 56.643 52.2857V17.4286C56.643 16.8508 56.4134 16.2967 56.0049 15.8881C55.5963 15.4795 55.0422 15.25 54.4644 15.25H45.7501C45.1723 15.25 44.6182 15.4795 44.2096 15.8881C43.8011 16.2967 43.5715 16.8508 43.5715 17.4286ZM47.9287 19.6071H52.2858V50.1071H47.9287V19.6071Z"
                fill={color}/>
            <path
                d="M26.1429 28.3223C25.5651 28.3223 25.011 28.5518 24.6024 28.9604C24.1939 29.3689 23.9644 29.923 23.9644 30.5008V52.2866C23.9644 52.8643 24.1939 53.4185 24.6024 53.827C25.011 54.2356 25.5651 54.4651 26.1429 54.4651H34.8572C35.435 54.4651 35.9891 54.2356 36.3977 53.827C36.8063 53.4185 37.0358 52.8643 37.0358 52.2866V30.5008C37.0358 29.923 36.8063 29.3689 36.3977 28.9604C35.9891 28.5518 35.435 28.3223 34.8572 28.3223H26.1429ZM32.6786 50.108H28.3215V32.6794H32.6786V50.108Z"
                fill={color}/>
            <path
                d="M6.53575 39.2148C5.95796 39.2148 5.40383 39.4444 4.99527 39.8529C4.58671 40.2615 4.35718 40.8156 4.35718 41.3934V52.2863C4.35718 52.8641 4.58671 53.4182 4.99527 53.8268C5.40383 54.2353 5.95796 54.4648 6.53575 54.4648H15.25C15.8278 54.4648 16.382 54.2353 16.7905 53.8268C17.1991 53.4182 17.4286 52.8641 17.4286 52.2863V41.3934C17.4286 40.8156 17.1991 40.2615 16.7905 39.8529C16.382 39.4444 15.8278 39.2148 15.25 39.2148H6.53575ZM13.0715 50.1077H8.71432V43.572H13.0715V50.1077Z"
                fill={color}/>
            <path
                d="M58.8214 56.6426H2.17857C1.60078 56.6426 1.04665 56.8721 0.638089 57.2807C0.229527 57.6892 0 58.2434 0 58.8211C0 59.3989 0.229527 59.9531 0.638089 60.3616C1.04665 60.7702 1.60078 60.9997 2.17857 60.9997H58.8214C59.3992 60.9997 59.9534 60.7702 60.3619 60.3616C60.7705 59.9531 61 59.3989 61 58.8211C61 58.2434 60.7705 57.6892 60.3619 57.2807C59.9534 56.8721 59.3992 56.6426 58.8214 56.6426Z"
                fill={color}/>
            <path
                d="M4.35698 34.8571C4.64369 34.8588 4.92791 34.8038 5.19334 34.6954C5.45877 34.587 5.7002 34.4273 5.90376 34.2254L24.8138 15.25L26.1427 22.2214V22.4175C26.1334 22.5262 26.1334 22.6356 26.1427 22.7443C26.2244 22.8906 26.3192 23.0291 26.4259 23.1582V23.3325C26.5714 23.461 26.7324 23.5707 26.9052 23.6593H27.1013C27.4735 23.8677 27.8947 23.973 28.3213 23.9643H28.757C28.757 23.9643 28.8659 23.9643 28.9313 23.9643L29.3234 23.79C29.4577 23.7282 29.5824 23.6475 29.6938 23.5504H29.868L45.7498 7.42893V8.71429C45.7498 9.29208 45.9794 9.84621 46.3879 10.2548C46.7965 10.6633 47.3506 10.8929 47.9284 10.8929C48.5062 10.8929 49.0603 10.6633 49.4689 10.2548C49.8774 9.84621 50.107 9.29208 50.107 8.71429V2.17857C50.1035 1.89388 50.0443 1.61263 49.9327 1.35071C49.7116 0.818382 49.2886 0.395356 48.7563 0.174286C48.4943 0.0626534 48.2131 0.00344212 47.9284 0H41.3927C40.8149 0 40.2608 0.229527 39.8522 0.638089C39.4436 1.04665 39.2141 1.60078 39.2141 2.17857C39.2141 2.75636 39.4436 3.31049 39.8522 3.71905C40.2608 4.12762 40.8149 4.35714 41.3927 4.35714H42.678L29.6066 17.4286L28.3213 10.4571C28.3213 10.4571 28.3213 10.4571 28.3213 10.2829C28.2723 10.1483 28.2141 10.0172 28.147 9.89071C28.0798 9.75947 27.9995 9.63538 27.9073 9.52036C27.9115 9.46234 27.9115 9.40409 27.9073 9.34607H27.733L27.3627 9.10643C27.2418 9.03327 27.1091 8.98167 26.9705 8.95393H26.6002H25.707C25.707 8.95393 25.598 8.95393 25.5327 8.95393L25.1405 9.12821C25.0063 9.18999 24.8816 9.27069 24.7702 9.36786H24.5959L2.81019 31.1536C2.606 31.3561 2.44392 31.5971 2.33332 31.8625C2.22272 32.128 2.16577 32.4128 2.16577 32.7004C2.16577 32.988 2.22272 33.2727 2.33332 33.5382C2.44392 33.8037 2.606 34.0446 2.81019 34.2471C3.22486 34.647 3.78101 34.8663 4.35698 34.8571Z"
                fill={color}/>
        </svg>
    )
}

export default GrowSkillsIcon