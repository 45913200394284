import {Navigate} from "react-router-dom";
import ForCompanies from "../../pages/ForCompanies";
import ForDevelopers from "../../pages/ForDevelopers";
import {routerType} from "../../types/router.types";
import ArticleDetail from "../../pages/Article/ArticleDetail";
import AllArticle from "../../pages/Article/AllArticle";


const pagesData: routerType[] = [
    {
        path: '',
        title: '',
        element: <Navigate to='companies'/>
    },
    {
        path: 'companies',
        title: 'ForCompanies',
        element: <ForCompanies/>
    },
    {
        path: 'candidates',
        title: 'ForDeveloper',
        element: <ForDevelopers/>
    },
    {
        path: 'article/:id',
        title: 'Article',
        element: <ArticleDetail/>
    },
    {
        path: 'article',
        title: 'AllArticle',
        element: <AllArticle/>
    }


]

export default pagesData