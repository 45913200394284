import React from 'react'

const Doc = () => {
  return (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4473 13.146L10.4465 20.1468C8.29851 22.2948 4.87765 22.2948 2.80923 20.1468C0.661247 17.9988 0.661247 14.578 2.80923 12.5096L12.9923 2.32654C14.4242 0.894554 16.6518 0.894554 18.0838 2.32654C19.5157 3.75853 19.5157 5.98606 18.0838 7.41805L9.17362 16.3282C8.45762 17.0442 7.34385 17.0442 6.62786 16.3282C5.91187 15.6122 5.91187 14.4984 6.62786 13.7824L12.3558 8.05449" stroke="#333333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default Doc