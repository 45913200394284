import React from 'react'
import {useTranslation} from 'react-i18next'
import CloseSquare from "../../../assets/icons/CloseSquare";
import BaseModal from "../Modal/BaseModal";

const ImprintModal = ({open = false, onClose}: { open?: boolean, onClose: any }) => {

    const {t} = useTranslation()

    return (
        <BaseModal visible={open}>
            <div
                className='relative px-8 sm:px-4 py-4 flex flex-col items-start w-full max-h-[90vh] sm:w-full sm:min-h-[100vh]  gap-y-4 '>
                <div className='flex flex-row items-center justify-between w-full'>
                    <p className='text-[40px] font-medium text-[#333333] sm:text-lg'>
                        {t<string>('Imprint')}
                    </p>
                    <CloseSquare
                        onClick={onClose}
                        className='cursor-pointer'/>
                </div>
                <hr className='w-full'/>
                <div className=' w-full h-full mt-[20px] pt-4 overflow-y-scroll'>
                    <p className='whitespace-pre-wrap text-[#555C68] text-lg'>
                        {t<string>("InformationAccording")}
                    </p>
                    <p className='whitespace-pre-wrap mt-[34px] text-[#333333] '>
                        {t<string>("RedevLtd")}
                    </p>
                    <p className='whitespace-pre-wrap mt-[20px] text-[#333333]'>
                        {t<string>("ObjectTheCompany")}
                    </p>
                    <br/>
                    <br/>
                    <p>
                        {t<string>('tel_label')} <a className="underline underline-offset-2"
                                                    href="tel:+4317071949">+43-1-7071949</a>
                    </p>
                    <p>
                        {t<string>('email_label')} <a className="underline underline-offset-2"
                                                      href="mailto:office@redev.at">office@redev.at</a>
                    </p>

                    <p className='whitespace-pre-wrap mt-[48px] text-[#5B68FF] text-lg'>
                        {t<string>("EuDispute")}
                    </p>

                    <p className='whitespace-pre-wrap text-[#555C68] '>
                        {t<string>("EuDisputeText")}
                    </p>

                    <p className='whitespace-pre-wrap mt-[48px] text-[#5B68FF] text-lg'>
                        {t<string>("LiabilityForContents")}
                    </p>
                    <p className='whitespace-pre-wrap text-[#555C68] '>
                        {t<string>("LiabilityForContentsText")}
                    </p>

                    <p className='whitespace-pre-wrap mt-[48px] text-[#5B68FF] text-lg'>
                        {t<string>("LiabilityForLinks")}
                    </p>
                    <p className='whitespace-pre-wrap text-[#555C68] '>
                        {t<string>("LiabilityForLinksText")}
                    </p>

                    <p className='whitespace-pre-wrap mt-[48px] text-[#5B68FF] text-lg'>
                        {t<string>("CopyrightNotice")}
                    </p>
                    <p className='whitespace-pre-wrap text-[#555C68] '>
                        {t<string>("CopyrightNoticeText")}
                    </p>

                    <p className='whitespace-pre-wrap mt-[48px] text-[#5B68FF] text-lg'>
                        {t<string>("PictureCredits")}
                    </p>
                    <p className='whitespace-pre-wrap text-[#555C68] '>
                        {t<string>("PictureCreditsText")}
                    </p>
                </div>
            </div>
        </BaseModal>

    )
}

export default ImprintModal
