import React from "react";
import {Trans, useTranslation} from "react-i18next";

import TrustedBy from "../../../../common/components/TrustedBy/TrustedBy";
import {AppStoreButton, GooglePlayButton,} from "../../../../assets/img/StoresButton";

const FirstView = () => {
    const {t} = useTranslation();

    return (
        <section id="first" className="max-w-7xl flex mx-auto">
            <div
                className=" flex flex-col bg-first-section-desktop sm:bg-first-section-mobile sm:bg-cover minlg:rounded-xl sm:bg-center minmd:bg-center bg-no-repeat relative minlg:h-[50rem] "
            >
                <div
                    className=" flex flex-col flex-start minlg:gap-y-8 h-full ml-32 lg:ml-6 lg:py-20 md:ml-20 sm:ml-6 pt-40 sm:py-20 sm:space-y-6 ">
                    <div className='flex flex-col gap-y-5 lg:mt-5'>
                        <h1 className="text-[48px] font-bold sm:text-2xl lg:text-3xl">
                            <Trans i18nKey="first_view_dev_title">
                                It's time for the ONLY<br/>way of <span
                                className='text-white bg-[#3869F1] rounded-[7px] px-2'>SUCCESS!</span>
                            </Trans>

                        </h1>
                        <p className="text-lg text-justify sm:text-left sm:w-[60%] w-[48%]  sm:text-xs text-slate-800">
                            {t<string>("firstText")}
                        </p>
                    </div>
                    <div className="flex flex-row w-full sm:flex-col sm:space-y-4 sm:space-x-0 space-x-6  ">
                        <AppStoreButton href="https://apps.apple.com/at/app/redev/id1633895339?l=en"/>
                        <GooglePlayButton href="https://play.google.com/store/apps/details?id=at.redev.android"/>
                        {/*<a href="https://app.redev.jobs" target="_blank"><WebAppButton/></a>*/}
                    </div>
                </div>
                <div className="">
                    <TrustedBy/>
                </div>
            </div>
        </section>
    );
};

export default FirstView
