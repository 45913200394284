import React from 'react'

const Pic = () => {
    return (
        <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.8608 0.688721H3.76953C1.6916 0.688721 0 2.37907 0 4.45825V22.5508C0 24.6287 1.69035 26.3203 3.76953 26.3203H21.862C23.94 26.3203 25.6316 24.6299 25.6316 22.5508V4.45825C25.6303 2.37907 23.94 0.688721 21.8608 0.688721ZM24.1225 22.5495C24.1225 23.796 23.1086 24.8112 21.8608 24.8112H3.76953C2.52302 24.8112 1.50781 23.7973 1.50781 22.5495V19.8227L7.59158 13.0625L15.8095 20.5328L20.3542 16.7458L24.1238 19.8865V22.5495H24.1225ZM24.1225 17.9248L20.353 14.7842L15.8508 18.5349L7.48531 10.9283L1.50781 17.5697V4.45825C1.50781 3.21175 2.52177 2.19653 3.76953 2.19653H21.862C23.1086 2.19653 24.1238 3.21049 24.1238 4.45825V17.9248H24.1225Z"
                fill="#333333"/>
            <path
                d="M18.0923 11.9949C20.1702 11.9949 21.8618 10.3045 21.8618 8.22534C21.8618 6.14616 20.1715 4.45581 18.0923 4.45581C16.0144 4.45581 14.3228 6.14616 14.3228 8.22534C14.3228 10.3045 16.0131 11.9949 18.0923 11.9949ZM18.0923 5.96487C19.3388 5.96487 20.354 6.97884 20.354 8.22659C20.354 9.47435 19.34 10.4883 18.0923 10.4883C16.8445 10.4883 15.8306 9.47435 15.8306 8.22659C15.8306 6.97884 16.8445 5.96487 18.0923 5.96487Z"
                fill="#333333"/>
        </svg>

    )
}

export default Pic