import React from 'react'

const CodeBracket =({ color, className }: { color?: string, className?: string}) => {
  return (
    <svg width="20" height="20"  fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 6.75L22.5 12l-5.25 5.25m-10.5 0L1.5 12l5.25-5.25m7.5-3l-4.5 16.5" fill={color}></path>
  </svg>
  )
}

export default CodeBracket