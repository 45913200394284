import {PropsWithChildren} from "react";

export type BaseModalProps<P = unknown> = P & PropsWithChildren<{ visible: boolean }>

const BaseModal = (props: PropsWithChildren<{ visible: boolean, modalClassName?: string }>) => {
    return (!props.visible ? null :
            <div
                className=" flex justify-center items-center overflow-y-auto overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur backdrop-brightness-50">
                <div className="relative  my-6 mx-auto w-3/4 sm:w-full sm:min-h-[100vh] max-w-6xl max-h-[98vh]">
                    <div
                        className={`border-0 rounded-lg sm:rounded-none shadow-lg relative flex flex-col items-center w-full bg-[#ffffff] sm:w-full sm:min-h-[100vh] outline-none focus:outline-none min-h-[400px] ${props.modalClassName ?? ''}`}>
                        {props.children}
                    </div>
                </div>
            </div>
    );
};

export default BaseModal
