import React from 'react'

const Interrogation = ({ className }: { className?: string }) => {
  return (
    <svg className={className} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="13.0444" cy="12.9565" r="12.9565" fill="#E1F5FE"/>
<circle cx="13.0444" cy="12.9565" r="12.8065" stroke="#E2E9EE" strokeOpacity="0.75" strokeWidth="0.3"/>
<path d="M17.858 10.0114C17.858 13.2912 14.4104 13.3416 14.4104 14.5537V14.8636C14.4104 15.1878 14.1546 15.4506 13.8391 15.4506H11.6661C11.3506 15.4506 11.0948 15.1878 11.0948 14.8636V14.4401C11.0948 12.6917 12.3849 11.9928 13.3598 11.4311C14.1958 10.9496 14.7082 10.6221 14.7082 9.98435C14.7082 9.14079 13.6609 8.58089 12.8143 8.58089C11.7104 8.58089 11.2007 9.11781 10.4844 10.0468C10.2912 10.2972 9.93883 10.3437 9.69099 10.1507L8.36642 9.11869C8.1233 8.9293 8.06884 8.57741 8.24055 8.31832C9.36532 6.62133 10.798 5.66797 13.0285 5.66797C15.3645 5.66797 17.858 7.54153 17.858 10.0114ZM14.7521 18.1897C14.7521 19.3225 13.8551 20.2441 12.7526 20.2441C11.6501 20.2441 10.7532 19.3225 10.7532 18.1897C10.7532 17.0569 11.6501 16.1354 12.7526 16.1354C13.8551 16.1354 14.7521 17.0569 14.7521 18.1897Z" fill="#0277BD"/>
</svg>

  )
}

export default Interrogation