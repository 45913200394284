// noinspection JSUnusedAssignment

import React, {useEffect, useState} from "react";
import axios from "axios";
import Arrow from "../../../../assets/img/Arrow";
import {useTranslation} from "react-i18next";
import Pic from "../../../../assets/icons/Pic";
import Video from "../../../../assets/icons/Video";
import Doc from "../../../../assets/icons/Doc";
import Code from "../../../../assets/icons/Code";
import GreenVec from "../../../../assets/icons/GreenVec";
import Coccinelle from "../../../../assets/icons/Coccinelle";
import Cup from "../../../../assets/icons/Cup";
import Interrogation from "../../../../assets/icons/Interrogation";
import _ from 'lodash'

type ShareData = {
    imgUrl: string;
    title: string;
    subtitle: string;
    text: string;
    icons: any[];
};

const ShareOnline = () => {
    const {t} = useTranslation();


    const [data, setData] = useState<ShareData[]>();
    const [index, setIndex] = useState<number>(0);
    const [first, setFirst] = useState<any>("");
    const [second, setSecond] = useState("");
    const [third, setThird] = useState("");

    useEffect(() => {
        axios
            .get("/data/sharedata.json")
            .then((res) => {
                setData(res.data);

                setFirst(res.data[1].imgUrl);
                setSecond(res.data[index].imgUrl);
                setThird(res.data[2].imgUrl);
            })
            .catch(() => {
            });
    }, []);


    const onNextSlide = () => {
        let newIndex = index + 1;

        if (newIndex === data?.length) {
            newIndex = 0;
        }
        setIndex(newIndex);
        onNextChangeState(newIndex);
    };
    const onNextChangeState = (newIndex: number) => {
        if (!data) {
            return;
        }
        // debugger
        setSecond(data[newIndex].imgUrl);

        if (newIndex - 1 > 0) {
            setFirst(data[newIndex - 1].imgUrl);
        } else if (newIndex === 0) {
            setFirst(data[2].imgUrl);
        } else {
            setFirst(data[0].imgUrl);
        }

        if (newIndex + 1 < data.length) {
            setThird(data[newIndex + 1].imgUrl);
        } else if (newIndex === data.length - 1) {
            setThird(data[0].imgUrl);
        } else {
            setThird(data[data.length - 1].imgUrl);
        }
    };

    const onPrevSlide = () => {
        let newIndex = index - 1;
        if (newIndex < 0 && data) {
            newIndex = data.length - 1;
        }
        setIndex(newIndex);
        onPrevChangeState(newIndex);
    };

    const onPrevChangeState = (newIndex: number) => {
        if (!data) {
            return;
        }
        setSecond(data[newIndex].imgUrl);

        if (newIndex - 1 > 0) {
            setThird(data[newIndex - 1].imgUrl);
        } else if (newIndex === 0) {
            setThird(data[2].imgUrl);
        } else {
            setThird(data[0].imgUrl);
        }

        if (newIndex + 1 < data.length) {
            setFirst(data[newIndex + 1].imgUrl);
        } else if (data.length - 1 === newIndex) {
            setFirst(data[0].imgUrl);
        } else {
            setFirst(data[data.length - 1].imgUrl);
        }
    };
    console.log(index);

    const getImgByName = (name: string) => {
        if (name === "image") {
            return <Pic/>;
        } else if (name === "Video") {
            return <Video/>;
        } else if (name === "code") {
            return <Code/>;
        } else if (name === "GreenVec") {
            return <GreenVec/>;
        } else if (name === "Doc") {
            return <Doc/>;
        } else if (name === "Trophy") {
            return <Cup/>;
        } else if (name === "coccinelle") {
            return <Coccinelle/>;
        } else if (name === "questmark") {
            return <Interrogation/>;
        }
    };


    return (
        <section id='socialmedia' className="bg-[#eef2ff] mt-20 py-10 sm:p-4 lg:pl-4">
            <div className="max-w-7xl mx-auto grid grid-cols-12">
                <div className="col-span-7 sm:col-span-12 h-full flex flex-col justify-between gap-y-7 minmd:pl-4">
                    <div
                        className="tracking-wide w-full md:text-2xl text-4xl sm:text-xl font-semibold leading-[63px] text-[#1B2A3E]">
                        {t<string>("sharetitle")}
                        <span className="text-[#3869F1]">{t<string>("sharetitle1")}</span>
                    </div>

                    <div className="flex flex-col w-full pr-16 sm:pr-0 justify-start items-start gap-y-4">
                        <div className="w-full tracking-wide  md:text-xl text-2xl sm:text-sm text-[#1B2A3E]">
                            {t<string>(_.nth(data, index)?.subtitle ?? '')}
                        </div>
                        <div className="flex flex-row w-full gap-x-3 justify-start items-center">
                            {_.nth(data, index)?.icons?.map((icon: any, i) => (
                                <div className="flex space-x-2 items-center" key={index * 100 + i}>
                                    <div className="text-sm font-semibold sm:text-xs">
                                        {t<string>(icon.title)}
                                    </div>
                                    <div className="">
                                        {getImgByName(icon.icon)}
                                    </div>
                                    <div className="flex text-xs">{icon.text}</div>
                                </div>
                            ))}
                        </div>
                        <div
                            className="text-md box-border min-h-[100px] font-light tracking-wide text-justify leading-relaxed text-[#424242] sm:text-xs ">
                            {t<string>(_.nth(data, index)?.text ?? '')}
                        </div>

                    </div>

                    <div className="flex flex-row gap-x-3 sm:hidden">
                        <button
                            onClick={onNextSlide}
                            type="button"
                            className={`transition-colors rounded-md duration-500 group bg-[#DAE4FB]  flex justify-center items-center w-[46px] h-[46px]  hover:bg-[#3869F1]`}>
                            <Arrow className="w-6 h-6 fill-[#3869F1] group-hover:fill-white"/>
                        </button>
                        <button
                            onClick={onPrevSlide}
                            type="button"
                            className={`transition-colors rounded-md duration-500 group bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px]  hover:bg-[#3869F1]`}>
                            <Arrow className="w-6 h-6 rotate-180 fill-[#3869F1] group-hover:fill-white"/>
                        </button>
                    </div>

                </div>
                <div className="col-span-5 sm:col-span-12 flex items-center justify-center sm:justify-between">
                    <button
                        onClick={onNextSlide}
                        type="button"
                        className={`minmd:hidden transition-colors rounded-md duration-500 group bg-[#DAE4FB]  flex justify-center items-center w-8 h-8 hover:bg-[#3869F1]`}>
                        <Arrow className="w-4 h-4 fill-[#3869F1] group-hover:fill-white"/>
                    </button>
                    <img
                        className="bg-mobile-frame w-full max-w-[294px] sm:max-w-[160px] p-3 sm:p-2 bg-cover bg-center bg-no-repeat"
                        src={_.nth(data, index)?.imgUrl}
                    />
                    <button
                        onClick={onPrevSlide}
                        type="button"
                        className={`minmd:hidden transition-colors rounded-md duration-500 group bg-[#DAE4FB] flex justify-center items-center w-8 h-8  hover:bg-[#3869F1]`}>
                        <Arrow className="w-4 h-4 rotate-180 fill-[#3869F1] group-hover:fill-white"/>
                    </button>
                </div>
            </div>
        </section>
    )


};

export default ShareOnline;
