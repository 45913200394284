import {Drawer} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate} from 'react-router-dom'
import Xicon from '../../../assets/img/Xicon'
import Redevlogo from '../../../assets/img/Redevlogo'
import GradiantUnderline from '../GradiantUnderline/GradiantUnderline'
import {companyPageNavItems, MainPageNavItems} from '../NavBar/NavConstants'

const HamburgerMenu = ({ open, onClose }: { open: boolean, onClose: any }) => {

    const { t, i18n } = useTranslation()

    const location = useLocation()
    const navigate = useNavigate()

    const handleSwichPages = () => {
        if (location.pathname === '/companies') {
            navigate('candidates')
        }
        if (location.pathname === '/candidates') {
            navigate('companies')
        }
        onClose()
    }

    const handleChangeLang = (lng: string) => {
        i18n.changeLanguage(lng)
        localStorage.setItem('lng', lng)
        onClose()
    }

    return (
        <Drawer anchor='right' open={open} onClose={onClose}>
            <div className='h-screen w-screen  px-6 pt-10'>
                 <div className='flex justify-between mb-12'>

                    <Redevlogo />
                    <button type='button' onClick={() => onClose()}><Xicon /></button>
                 </div>

                <ul>
                    {location.pathname === '/candidates' ?
                        MainPageNavItems.map((item, index) => (
                            <li key={index + 1} onClick={() => onClose()} className='mt-8'>
                                <a href={item.href}>{t<string>(item.title)}</a>
                            </li>
                        )) :
                        companyPageNavItems.map((item, index) => (
                            <li key={index + 1} onClick={() => onClose()} className='mt-8'>
                                <a href={item.href}>{t<string>(item.title)}</a>
                            </li>
                        ))
                    }
                </ul>

                <div className='flex justify-between mt-24'>
                    {!location.pathname.includes('article') ? (<button onClick={handleSwichPages} type='button' className='bg-[#202334] rounded-lg h-9 w-36 text-white'>
                        {location.pathname === '/candidates' ? t<string>('For Companies') : t<string>('For Candidates')}
                    </button>) :
                        (<a href='https://dashboard.redev.at' >
                            <button className='bg-[#202334] rounded-lg h-9 w-36 text-white '>
                                {t<string>('get started')}
                            </button>
                        </a>)
                    }
                    <div>
                        <div className='inline-block'>
                            <button type='button' onClick={() => handleChangeLang('en')}>EN</button>
                            <GradiantUnderline w='w-[25px]' isVisible={i18n.language === 'en'} />
                        </div>
                        <div className='inline-block ml-4'>
                            <button type='button' onClick={() => handleChangeLang('de')}>DE</button>
                            <GradiantUnderline w='w-[25px]' isVisible={i18n.language === 'de'} />
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    )
}

export default HamburgerMenu
