import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import candidates from "../../../../assets/ai-job-generator.png";
import discover from "../../../../assets/ai03.jpg";
import posting from "../../../../assets/ai02.jpg";
import PlayerModal from "../../../../Components/PlayerModal/PlayerModal";
import OrangePlayIcon from "../../../../assets/icons/OrangePlayIcon";

const Community = () => {
    const {t} = useTranslation();

    const [showPlayer, setShowPlayer] = useState<boolean>(false)
    const [url, setUrl] = useState<string>('')

    return (
        <section id="hit" className="minmd:pt-16  md:pt-10  max-w-7xl mx-auto">
            <div className="ml-20 md:mx-6 space-y-6">
                <h4 className=" text-4xl md:text-2xl w-[50%] lg:w-[100%] tracking-wide   md:leading-[36px] font-semibold leading-[43px] text-[#1B2A3E]">
                    {t<string>("ctitle1")}
                    <span className="text-[#3869F1]"> {t<string>("ctitle2")}</span>
                </h4>
                <p className="mx-auto text-sm leading-[22px]">{t<string>("ctext")}</p>
            </div>

            <div className=" minmd:px-14  mt-10 md:px-0 flex flex-row minmd:space-x-10 md:mx-6">
                <div className=" flex flex-col relative md:hidden  z-10">
                    <div>
                        <div className="w-[8px] h-[8px] bg-[#3869F1] rounded-full absolute left-3 z-0"/>

                        <div className="w-[2px] bg-[#3869F1] absolute inset-y-0 left-4 bottom-12  z-0"/>
                    </div>

                    <div
                        className="w-8 h-8 bg-[#3869F1] rounded-full absolute top-[67%] lg:top-[73%] text-white flex items-center justify-center font-bold text-lg">
                        3
                    </div>
                </div>

                <div className=" flex flex-col relative  md:hidden z-10">
                    <div>
                        <div className="w-[8px] h-[8px] bg-[#3869F1] rounded-full absolute left-3 z-0"/>

                        <div className="w-[2px] bg-[#3869F1] absolute top-0 left-4 bottom-1/2  z-0"/>
                    </div>

                    <div
                        className="w-8 h-8 bg-[#3869F1] rounded-full absolute bottom-[64%] lg:bottom-[60%] text-white flex items-center justify-center font-bold text-lg">
                        2
                    </div>
                </div>

                <div className=" flex flex-col md:hidden  z-10">
                    <div
                        className="w-8 h-8 bg-[#3869F1] rounded-full relative text-white flex items-center justify-center font-bold text-lg">
                        1
                        <div className="w-[2px] bg-[#3869F1] absolute top-8 -bottom-40 z-0"/>
                    </div>
                </div>


                <div className="flex flex-col minmd:space-y-10 ">
                    <div className="flex md:flex-col minmd:-translate-x-16 minmd:space-x-10 md:space-x-8 sm:mb-10 ">
                        <div className=" flex flex-col  minmd:hidden  z-10">
                            <div
                                className="w-8 h-8 bg-[#3869F1] rounded-full relative text-white flex items-center justify-center font-bold text-lg">
                                1
                                <div className="w-[2px] bg-[#3869F1] absolute top-8 -bottom-40 z-0"/>
                            </div>
                        </div>
                        <div className="basis-[40%] lg:basis-[90%]  md:basis-[100%] ">
                            <h6 className="text-md lg:text-sm leading-[28px] font-semibold">
                                {t<string>("innovative_job_posting")}
                            </h6>
                            <p className="leading-6 text-left text-sm mt-6 text-[#444444]">
                                {t<string>("jobPostingProcess")}
                            </p>
                        </div>
                        <div className="relative">
                            <img
                                onClick={() => {
                                    setShowPlayer(true)
                                    setUrl('https://redev-demo.mimteam.net/ai-job-generator.mp4')
                                }}
                                className="w-[39rem]  rounded-[12px] minmd:-translate-y-2 minmd:translate-x-20 cursor-pointer"
                                src={candidates}
                                alt=""/>
                            <OrangePlayIcon
                                onClick={() => {
                                    setShowPlayer(true)
                                    setUrl('https://redev-demo.mimteam.net/ai-assessment.mp4')
                                }}
                                className='absolute left-[75px] bottom-0 sm:bottom-0 lg:bottom-8  sm:left-0 cursor-pointer'/>
                        </div>
                    </div>
                    <div className="flex md:flex-col minmd:-translate-x-24 minmd:space-x-10 md:space-x-8 sm:mb-10">
                        <div className=" flex flex-col minmd:hidden  z-10">
                            <div
                                className="w-8 h-8 bg-[#3869F1] rounded-full relative text-white flex items-center justify-center font-bold text-lg">
                                2
                                <div className="w-[2px] bg-[#3869F1] absolute top-8 -bottom-40 z-0"/>
                            </div>
                        </div>
                        <div className="basis-[40%] lg:basis-[90%]  text-left">
                            <h6 className="text-md leading-[28px] font-semibold">
                                {t<string>("candidates_assessment")}
                            </h6>
                            <p className="leading-6 text-left text-sm mt-6 text-[#444444]">
                                {t<string>("createAssessmentText")}
                            </p>
                        </div>
                        <div className='relative'>
                            <img
                                onClick={() => {
                                    setShowPlayer(true)
                                    setUrl('https://redev-demo.mimteam.net/ai-assessment.mp4')
                                }}
                                className="w-[39rem] aspect-video rounded-[12px] minmd:-translate-y-2 minmd:translate-x-10 cursor-pointer"
                                src={posting}
                                alt=""/>
                            <OrangePlayIcon
                                onClick={() => {
                                    setShowPlayer(true)
                                    setUrl('https://redev-demo.mimteam.net/ai-assessment.mp4')
                                }}
                                className='absolute left-[40px] sm:left-0 sm:bottom-0 lg:bottom-16 bottom-0 cursor-pointer'/>
                        </div>
                    </div>
                    <div className="flex md:flex-col minmd:-translate-x-24 md:space-x-8">
                        <div className=" flex flex-col minmd:hidden  z-10">
                            <div
                                className="w-8 h-8 bg-[#3869F1] rounded-full relative text-white flex items-center justify-center font-bold text-lg">
                                3
                                <div className="w-[2px] bg-[#3869F1] absolute top-8 -bottom-40 z-0"/>
                            </div>
                        </div>
                        <div className="basis-[40%] lg:basis-[90%] ">
                            <h6 className="text-md leading-[28px] font-semibold">
                                {t<string>("discover_your_dream_talents")}
                            </h6>
                            <p className="leading-6 text-left text-sm mt-6 text-[#444444]">
                                {t<string>("grab_coffee_and_enjoy_text")}
                            </p>
                        </div>
                        <div>
                            <img className="w-[39rem] rounded-[12px] aspect-video minmd:-translate-y-2" src={discover} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <PlayerModal show={showPlayer} videoUrl={url}
                         closeModalFn={() => setShowPlayer(false)}/>

        </section>
    );
};

export default Community;
