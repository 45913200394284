import {useEffect} from "react";
import {useLocation} from "react-router-dom";

const ScrollToTop = () => {

    const {pathname} = useLocation();

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            window.scroll({top: 0, behavior: "smooth"});
        }
        return () => {
            isCancelled = true
        }
    }, [pathname]);
    return null
}

export default ScrollToTop
