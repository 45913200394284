import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Pagination} from "swiper";
import Bag from "../../../../assets/icons/Bag";
import User from "../../../../assets/icons/User";
import Notes from "../../../../assets/icons/Notes";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const JobSlider = (props: any) => {
    const {t} = useTranslation();
    const location = useLocation();

    return (
        <div className="max-w-4xl mx-6 flex flex-col md:mt-20 mt-28 items-center ">
            <h3 className="font-bold text-2xl leading-9  mb-8 text-[#1B2A3E]">
                {location.pathname === "/candidates"
                    ? "Popular Jobs"
                    : "Recent Activities"}
            </h3>
            <p className="text-xs leading-[22px] text-center text-[#6b7280] ">
                {t<string>("thirdText")}
            </p>
            <div className="">
                <div className="flex items-center mx-auto max-w-[300px] ">
                    <Swiper spaceBetween={50} pagination={true} modules={[Pagination]}>
                        {props.oportunityList.map((oportunityItem: any, i: number) => {
                            return (
                                <div className="" key={i}>
                                    <SwiperSlide
                                        key={i}
                                        className="shadow-md  shadow-slate-100 mt-4 mb-10 outline  outline-[#f9fafb]  outline-1 rounded-[20px] w-[300px] h-[260px] drop-shadow-xl     "
                                    >
                                        <div className="ml-6 my-4 mr-6">
                                            <div className="">
                                                <h5 className="text-[#010101] mt-6  font-semibold text-sm ">
                                                    {oportunityItem.title}
                                                </h5>

                                                <div className="flex space-x-2 ">
                          <span className="flex items-center">
                            <span
                                className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-[#1e3a8a] opacity-75" />
                            <span className="relative inline-flex rounded-full h-3 w-3 bg-[#1e3a8a]" />
                          </span>
                                                    <p className="font-medium text-[#1e3a8a] text-xs leading-8 opacity-75">
                                                        {oportunityItem.subtitle}
                                                    </p>{" "}
                                                </div>
                                                <p className="font-medium tracking-wide  leading-relaxed text-[#6b7280] text-xs leading-8">
                                                    {oportunityItem.country}
                                                </p>
                                            </div>
                                        </div>
                                        <hr className="mt-5  mb-6 mx-4"/>

                                        <div className=" space-x-6 mx-6 flex items-center justify-center mb-6 ">
                                            <div className="flex flex-col items-center text-sm space-x-1">
                                                <Notes/>
                                                <div className="flex sm:flex-col sm:items-center">
                                                    <p className="text-xs leading-[22px] text-[#6b7280] ">
                                                        {oportunityItem.positions}
                                                    </p>
                                                    <p className="text-[#6b7280] sm:text-xs">{t<string>('item1')}</p>
                                                </div>
                                            </div>
                                            <div className="flex flex-col items-center text-sm space-x-1">
                                                <User/>
                                                <div className="flex sm:flex-col sm:items-center">
                                                    <p className="text-xs leading-[22px] text-[#6b7280]">
                                                        {oportunityItem.sourced}
                                                    </p>
                                                    <p className="text-[#6b7280] sm:text-xs">{t<string>('item2')}</p>
                                                </div>
                                            </div>

                                            <div className="flex flex-col items-center text-sm space-x-1">
                                                <Bag/>
                                                <div className="flex sm:flex-col sm:items-center">
                                                    <p className="text-xs leading-[22px] text-[#6b7280]">
                                                        {oportunityItem.hired}
                                                    </p>
                                                    <p className="text-[#6b7280] sm:text-xs">{t<string>('item3')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                </div>
                            );
                        })}
                    </Swiper>
                </div>
            </div>
            {" "}
        </div>
    );
};

export default JobSlider;
