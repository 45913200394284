import React from 'react'
import {useTranslation} from 'react-i18next'

import logo1 from '../../../assets/wheatehr.jpg'
import logo2 from '../../../assets/indextor.jpg'
import CompanyLogo3 from '../../../assets/img/CompanyLogo3'
import logo4 from '../../../assets/metro.jpg'

const TrustedBy = () => {
    const {t} = useTranslation()

    return (
        <div
            className='absolute bg-white shadow-md shadow-slate-100 w-2/3 sm:w-[95%] lg:w-[70%] -bottom-28 left-[12rem] sm:left-20 sm:-bottom-14 lg:-bottom-24 lg:left-36 sm:left-[2.5%] rounded-[30px] sm:rounded[10px] py-4 px-2 flex flex-col sm:gap-y-2'>
            <div>
                <p className='text-[#9ca3af] text-lg sm:text-xs  pt-6 sm:pt-2 lg:pt-2 text-center'>{t<string>('TRUSTED BY')}</p>
            </div>
            <div className='grid grid-cols-12 sm:mx-2 '>
                <div className='col-span-3 flex items-center justify-center'>
                    <img src={logo1}/>
                </div>
                <div className='col-span-3 flex items-center justify-center'>
                    <img src={logo2}/>
                </div>
                <div className='col-span-3 flex items-center justify-center'>
                    <CompanyLogo3/>
                </div>
                <div className='col-span-3 flex items-center justify-center'>
                    <img src={logo4}/>
                </div>
            </div>
        </div>
    )
}

export default TrustedBy