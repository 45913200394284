const RightArrow = ({className}: { className?: string }) => {
    return (
        <svg className={className} width="121" height="12" viewBox="0 0 121 12" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM121 6L111 0.226497V11.7735L121 6ZM4 7C4.55228 7 5 6.55228 5 6C5 5.44772 4.55228 5 4 5V7ZM10 5C9.44772 5 9 5.44772 9 6C9 6.55228 9.44772 7 10 7V5ZM16 7C16.5523 7 17 6.55228 17 6C17 5.44772 16.5523 5 16 5V7ZM22 5C21.4477 5 21 5.44772 21 6C21 6.55228 21.4477 7 22 7V5ZM28 7C28.5523 7 29 6.55228 29 6C29 5.44772 28.5523 5 28 5V7ZM34 5C33.4477 5 33 5.44772 33 6C33 6.55228 33.4477 7 34 7V5ZM40 7C40.5523 7 41 6.55228 41 6C41 5.44772 40.5523 5 40 5V7ZM46 5C45.4477 5 45 5.44772 45 6C45 6.55228 45.4477 7 46 7V5ZM52 7C52.5523 7 53 6.55228 53 6C53 5.44772 52.5523 5 52 5V7ZM58 5C57.4477 5 57 5.44772 57 6C57 6.55228 57.4477 7 58 7V5ZM64 7C64.5523 7 65 6.55228 65 6C65 5.44772 64.5523 5 64 5V7ZM70 5C69.4477 5 69 5.44772 69 6C69 6.55228 69.4477 7 70 7V5ZM76 7C76.5523 7 77 6.55228 77 6C77 5.44772 76.5523 5 76 5V7ZM82 5C81.4477 5 81 5.44772 81 6C81 6.55228 81.4477 7 82 7V5ZM88 7C88.5523 7 89 6.55228 89 6C89 5.44772 88.5523 5 88 5V7ZM94 5C93.4477 5 93 5.44772 93 6C93 6.55228 93.4477 7 94 7V5ZM100 7C100.552 7 101 6.55228 101 6C101 5.44772 100.552 5 100 5V7ZM106 5C105.448 5 105 5.44772 105 6C105 6.55228 105.448 7 106 7V5ZM112 7C112.552 7 113 6.55228 113 6C113 5.44772 112.552 5 112 5V7ZM118 5C117.448 5 117 5.44772 117 6C117 6.55228 117.448 7 118 7V5ZM1 7H4V5H1V7ZM10 7H16V5H10V7ZM22 7H28V5H22V7ZM34 7H40V5H34V7ZM46 7H52V5H46V7ZM58 7H64V5H58V7ZM70 7H76V5H70V7ZM82 7H88V5H82V7ZM94 7H100V5H94V7ZM106 7H112V5H106V7Z"
                fill="#54627B"/>
        </svg>

    )
}
export default RightArrow