import React from 'react'

const CreateMAIcon = ({color, className }: { color?: string, className?: string }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 64 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2166 43.5531V32.3848L23.2582 40.2519C28.5556 43.713 35.3989 43.713 40.6961 40.2519L52.7379 32.3848V43.5531C52.7379 44.0345 52.5819 44.5028 52.293 44.8878L52.2898 44.8919L52.2862 44.8969L52.277 44.9088L52.2527 44.9405L52.2168 44.9859L52.1777 45.0349C52.1163 45.1108 52.0317 45.2125 51.9232 45.3365C51.7064 45.5841 51.3941 45.921 50.9824 46.3164C50.1591 47.1065 48.9346 48.1347 47.277 49.1547C43.9469 51.204 38.9134 53.192 31.9772 53.192C25.041 53.192 20.0075 51.204 16.6775 49.1547C15.0199 48.1347 13.7954 47.1065 12.9721 46.3164C12.5603 45.921 12.248 45.5841 12.0312 45.3365C11.9228 45.2125 11.8381 45.1108 11.7766 45.0349C11.7459 44.9966 11.721 44.9652 11.7018 44.9405L11.6774 44.9088L11.6683 44.8969L11.6646 44.8919C11.3758 44.5069 11.2166 44.0345 11.2166 43.5531Z" fill={color}  />
            <path d="M62.1134 20.9311L38.2659 36.5114C34.4468 39.0065 29.5135 39.0065 25.6943 36.5114L5.28783 23.1792V39.0881C5.28783 40.3165 4.29194 41.3125 3.06347 41.3125C1.835 41.3125 0.839111 40.3165 0.839111 39.0881V20.5518C0.839111 20.2915 0.883836 20.0416 0.965989 19.8095C0.882056 19.5718 0.838014 19.3185 0.839141 19.0593C0.842403 18.3048 1.22787 17.6034 1.86306 17.1963L25.778 1.86606C29.5577 -0.556917 34.4026 -0.556917 38.1823 1.86606L62.0974 17.1963C62.7324 17.6034 63.1179 18.3048 63.1212 19.0593C63.1242 19.7194 62.8338 20.3415 62.3382 20.7632C62.2673 20.8234 62.1923 20.8795 62.1134 20.9311Z" fill={color}  />
        </svg>
    )
}

export default CreateMAIcon