import React, {useEffect, useState} from "react";
import axios from "axios";
import Arrow from "../../../assets/img/Arrow";
import {Swiper, SwiperSlide} from "swiper/react";
import {useTranslation} from "react-i18next"
import Decoration from "../../../assets/img/Decoration";

export type TestimonialData = {
    "avatar": string | null
    "fullName": string | null
    "headline": string | null
    "text": string | null
}

const Costumers = () => {
    const {t} = useTranslation();
    const [swiper, setSwiper] = useState<any>(null)
    const [slideIndex, setSlideIndex] = useState<number>(0);
    const [testData, setTestData] = useState<[TestimonialData] | null>(null);
// console.log(slideIndex)
    useEffect(() => {
        axios
            .get("/data/testimonials.json")
            .then((res) => {
                // debugger
                setTestData(res.data);
                console.log(testData)
                swiper.updateContainerSize();
                // setSlideIndex(5)
            })
            .catch(() => {
            });
    }, [swiper]);


    return (
        <div
            className="max-w-7xl minmd:bg-gradient-to-r lg:bg-gradient-to-b md:mt-20 from-white to-[#eef2ff] mx-auto lg:mr-4 md:h-[25rem] lg:h-[22rem] sm:h-[26rem] minlg:h-96 sm:rounded-b-2xl minsm:rounded-r-2xl ">
            <div className="  max-w-7xl mx-auto ">
                <div className="flex md:flex-col md:items-center space-x-[20] lg:mx-6 ">
                    <div className="items-center minlg:ml-20 minlg:mt-10 lg:mt-4 w-[44%] md:w-[80%] sm:justify-center">
                        <div
                            className="rounded-full flex items-center justify-center h-12 w-12 bg-[#eef2ff] text-5xl text-[#3869F1] ">
                            <Decoration/></div>
                        <div
                            className=" text-3xl minlg:mb-20 sm:mb-0 sm:mt-0 minlg:-translate-y-5 minlg:translate-x-6 font-semibold lg:text-2xl   w-[80%] sm:w-[100%] lg:translate-x-10 lg:-translate-y-7">
                            {t<string>("customertitle")}
                        </div>
                    </div>
                    <div
                        className="relative flex flex-col minmd:w-[60%] items-center justify-center text-center minlg:mt-10  space-y-4 md: mr-0 mr-8 md:mt-0 lg:mt-10 sm:mt-0 lg:mr-4 lg:ml-4 ">
                        <div className="items-center font-bold text-2xl  md:text-xl w-[90%]">
                            {t<string>("customersecond")}
                        </div>
                        <p className="text-sm lg:text-xs lg:mt-6 w-[80%] sm:w-full text-center sm:line-clamp-3 ">
                            {testData && testData[slideIndex]?.text}
                        </p>
                        <div className="flex md:w-full">
                            <div
                                className="absolute top-1/2 sm:top-2/3 left-1 right-16 w-[90%] md:w-full md:top-14 lg:top-28">
                                {
                                    testData && testData.length > 0 ? (<Swiper
                                        loop
                                        slidesPerView={5}
                                        onSwiper={(s) => setSwiper(s)}
                                        centeredSlides
                                        onSlideChange={(e) => {
                                            // const i = e.activeIndex - 3
                                            setSlideIndex(e.realIndex)
                                        }}

                                    >

                                        {
                                            testData.map((dataItem: TestimonialData, i: number) => (
                                                <SwiperSlide key={i}
                                                             className=' mt-16 flex justify-center items-center'>
                                                    <div className='flex w-fit flex-col items-center justify-center'>
                                                        <img
                                                            className={`h-12 w-12 transition-all duration-300   ${slideIndex === i ? ' scale-[1.4]' : 'scale-[0.8] brightness-50'}`}
                                                            src={dataItem.avatar ?? ''} alt=""/>
                                                        <div
                                                            className={`flex flex-col minsm:mt-6 sm:mt-4 gap-y-2 ${slideIndex === i ? '' : 'hidden'}`}>
                                                            <div
                                                                className='text-xs sm:text-[11px] font-semibold  text-[#1B2A3E]'> {dataItem.fullName}</div>
                                                            <div
                                                                className='text-[9px] text-[#333333] truncate'>{dataItem.headline}</div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>) : ""}


                                <Arrow
                                    onClick={() => swiper.slideNext()}

                                    className="w-2 h-4 absolute top-1/2 fill-[#3869F1] minsm:-translate-y-1/2 cursor-pointer  right-1 rotate-180 sm:hidden z-10"
                                />
                                <Arrow
                                    onClick={() => swiper.slidePrev()}

                                    className="w-2 h-4 absolute top-1/2 fill-[#3869F1] minsm:-translate-y-1/2 cursor-pointer  left-1 sm:hidden  z-10 "
                                />


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Costumers;
