import React from "react";
import {BrowserRouter} from "react-router-dom";
import Layout from "./common/Layout/Layout";
import NavBar from "./common/components/NavBar/NavBar";
import Footer from "./common/components/Footer/Footer";
import Router from "./common/router/router";
import Subscribe from "./common/components/Subscribe/Subscribe";
import "swiper/css"
import "swiper/css/pagination"
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function App() {
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Layout>
                <NavBar/>
                <Router/>
                <Subscribe/>
                <Footer/>
            </Layout>
            <ToastContainer/>
        </BrowserRouter>

    );
}

export default App;
