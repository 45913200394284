import React from 'react'
import {useTranslation} from 'react-i18next'

import Cards from './Cards'

const Process = () => {
    const {t} = useTranslation()

    return (

        <section id='process'>
            <div className="  bg-[#eef2ff] h-[32rem] lg:h-[55rem] md:h-screen  sm:h-[56rem] ">
                <div className='max-w-7xl mx-auto'>
                    <div className='flex lg:flex-col mx-4 lg:mx-2'>
                        <div className='flex flex-col basis-[60%]  sm:mt-6 sm:pb-0 sm:p-2 sm:pl-3  mt-20 minmd:ml-2'>
                            <div className=' sm:space-y-6 space-y-8 '>
                                <div>
                                    <h3 className="text-4xl minmd:text-3xl  font-bold  text-[#1B2A3E] sm:hidden w-max "> {t<string>('atitle')}</h3>
                                    <h3 className="text-2xl font-semibold  text-[#1B2A3E] minsm:hidden "> {t<string>('atitle2')}</h3>
                                    <p className='text-2xl text-[#424242] sm:hidden '>{t<string>('asubtitle')}</p></div>
                                <div
                                    className='text-md w-[80%]  tracking-wide  sm:text-xs sm:leading-6 sm:w-[100%] text-[#6b7280] leading-8 mb-10'> {t<string>('atext1')}<span
                                    className='font-bold '> {t<string>('atext2')}</span> {t<string>('atext3')} </div>
                            </div>
                        </div>
                        <div className='mx-4'>
                            <Cards/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Process