import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from "./locales/en/default.json";
import de from "./locales/de/default.json";

export const resources = {
  en: {
    translation: {
      ...en,
    },
  },
  de: {
    translation: {
      ...de,
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  debug: true,
  lng: localStorage.getItem("lng") ?? "en",
});

export default i18n;
