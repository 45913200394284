import React from 'react'

const EarnMoneyIcon = ({ color, className }: { className?: string, color?: string }) => {
    return (
        <svg className={className}  width="32" height="32" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M33.5801 20.2561V24.6471"stroke={color} strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.5801 42.219V46.61"stroke={color} strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.1887 42.2126H35.7751C38.1902 42.2126 40.1661 40.2367 40.1661 37.8216C40.1661 35.4066 38.1902 33.4306 35.7751 33.4306H31.3841C28.9691 33.4306 26.9932 31.4547 26.9932 29.0397C26.9932 26.6246 28.9691 24.6487 31.3841 24.6487H37.9706"stroke={color} strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.5799 11.469C45.6551 11.469 55.5348 21.3487 55.5348 33.4239C55.5348 45.4991 45.6551 55.3788 33.5799 55.3788C21.5047 55.3788 11.625 45.4991 11.625 33.4239V26.8374" stroke={color} strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.4055 35.626L11.6235 26.844L2.84155 35.626" fill={color} strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M33.5801 2.68408C50.4854 2.68408 64.3169 16.5157 64.3169 33.4209C64.3169 50.3262 50.4854 64.1578 33.5801 64.1578"stroke={color} strokeWidth="5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default EarnMoneyIcon