export const MainPageNavItems = [
  {
    title: "Jobs",
    href: "#jobs",
  },
  {
      title: "AI process",
      href: "#process",
    },
    {
      title: "MasterClass",
      href: "#masterclass",
    },
    {
      title: "Social Media",
      href: "#socialmedia",
    },
  ];
  
  export const companyPageNavItems = [
    {
      title: "Jobs",
      href: "#jobs",
    },
    {
      title: "get started",
      href: "#socialmedia",
    },
    {
      title: "How it works",
      href: "#hit",
    },
    
    {
      title: "Blog",
      href: "#blog",
    },
  ];