import React from 'react'

const GreenVec = () => {
  return (
    <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.989868" width="41.0105" height="41.0105" rx="4" fill="#8AA6A3"/>
<path d="M26.8455 10.9544L34.353 18.8137C34.8021 19.2478 35.0267 19.8391 35.0267 20.4903C35.0267 21.1415 34.8021 21.7254 34.353 22.167L26.8455 30.0562C25.9548 30.9919 24.5027 30.9919 23.612 30.0562C22.7213 29.1206 22.7213 27.6086 23.612 26.673L27.2198 22.9005H10.9772C9.70477 22.9005 8.67932 21.8302 8.67932 20.5053C8.67932 19.1805 9.70477 18.1101 10.9697 18.1101H27.2123L23.6045 14.3376C22.7138 13.402 22.7138 11.89 23.6045 10.9544C24.5027 10.0188 25.9473 10.0188 26.8455 10.9544Z" fill="white"/>
</svg>

  )
}

export default GreenVec