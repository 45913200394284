import React from 'react'

const Cup = ({ className }: { className?: string }) => {
  return (
    <svg className={className} width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13.1186" cy="12.9565" r="12.9565" fill="#FFF1D0"/>
    <circle cx="13.1186" cy="12.9565" r="12.8065" stroke="#E2E9EE" strokeOpacity="0.75" strokeWidth="0.3"/>
    <path d="M20.391 8.40537L20.3556 8.10896H18.5826C18.6713 7.51615 18.7245 6.9059 18.7599 6.27822C18.7777 5.94694 18.5117 5.66797 18.2103 5.66797H8.05131C7.73218 5.66797 7.48397 5.94694 7.5017 6.27822C7.51943 6.9059 7.59035 7.51615 7.67899 8.10896H5.90604L5.85286 8.40537C5.83513 8.47511 5.6401 10.2536 6.81025 11.8576C7.57262 12.9038 8.76049 13.6186 10.303 13.9848C10.7994 14.473 11.349 14.8391 11.9341 15.0658C11.8632 15.7807 11.7745 16.4781 11.6859 17.1232H14.5758C14.4694 16.4606 14.3807 15.7807 14.3098 15.0658C14.8949 14.8566 15.4445 14.4904 15.9409 13.9848C17.4834 13.6186 18.6713 12.9038 19.4336 11.8576C20.6038 10.2536 20.391 8.47511 20.391 8.40537ZM7.3244 11.4217C6.57977 10.4105 6.49112 9.31202 6.49112 8.78895H7.78537C8.12223 10.4453 8.72503 11.9099 9.4874 13.0084C8.56547 12.6597 7.83856 12.1192 7.3244 11.4217ZM18.9195 11.4217C18.4053 12.1192 17.6784 12.6597 16.7565 13.0084C17.5366 11.8925 18.1217 10.4453 18.4585 8.78895H19.7528C19.7528 9.31202 19.6464 10.4105 18.9195 11.4217Z" fill="#FFC54D"/>
    <path d="M16.1362 19.5821H10.1082C9.9309 19.5821 9.78906 19.4426 9.78906 19.2683V17.0365C9.78906 16.8621 9.9309 16.7227 10.1082 16.7227H16.1362C16.3135 16.7227 16.4554 16.8621 16.4554 17.0365V19.2683C16.4554 19.4426 16.3135 19.5821 16.1362 19.5821Z" fill="#C19D72"/>
    <path d="M9.2039 20.2434H17.0403C17.2176 20.2434 17.3595 20.1039 17.3595 19.9295V19.267C17.3595 19.0926 17.2176 18.9531 17.0403 18.9531H9.2039C9.0266 18.9531 8.88477 19.0926 8.88477 19.267V19.9295C8.88477 20.0864 9.0266 20.2434 9.2039 20.2434Z" fill="#A88763"/>
    <path d="M14.5397 18.3446H11.703C11.5789 18.3446 11.4902 18.2574 11.4902 18.1354V17.6995C11.4902 17.5774 11.5789 17.4902 11.703 17.4902H14.5397C14.6638 17.4902 14.7525 17.5774 14.7525 17.6995V18.1354C14.7525 18.2574 14.6461 18.3446 14.5397 18.3446Z" fill="#FFC54D"/>
    </svg>
    
  )
}

export default Cup