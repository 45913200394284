import React from 'react'

const Coccinelle =({ className }: { className?: string }) => {
  return (
    <svg className={className} width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="13.0815" cy="12.9565" r="12.9565" fill="#FFD6CB"/>
<circle cx="13.0815" cy="12.9565" r="12.8065" stroke="#E2E9EE" strokeOpacity="0.75" strokeWidth="0.3"/>
<path d="M10.0641 14.1652L7.07835 17.5729C6.87954 17.8 6.90314 18.1479 7.13057 18.3457C7.35834 18.5442 7.70729 18.5207 7.90611 18.2936L10.8918 14.8858C11.0909 14.6587 11.0669 14.3114 10.8395 14.1128C10.7358 14.0227 10.607 13.9785 10.4786 13.9785C10.3252 13.9785 10.1725 14.0417 10.0641 14.1652Z" fill="url(#paint0_linear_5_1928)"/>
<path d="M10.7418 11.7332L6.25735 12.4323C5.95878 12.4788 5.75267 12.7604 5.79964 13.0582C5.84627 13.3558 6.12853 13.5612 6.4272 13.5145L10.9119 12.8152C11.2105 12.769 11.4166 12.4874 11.3697 12.1896C11.3274 11.9204 11.0923 11.7266 10.8268 11.7266C10.7987 11.7266 10.7703 11.7287 10.7418 11.7332Z" fill="url(#paint1_linear_5_1928)"/>
<path d="M7.20472 7.7177C7.03555 7.96759 7.10281 8.30964 7.35304 8.47782L11.1176 11.0065C11.368 11.1747 11.7112 11.1082 11.8802 10.8584V10.8582C12.0489 10.6083 11.9822 10.2664 11.7316 10.0981L7.96726 7.5694C7.87367 7.50656 7.76708 7.47656 7.66163 7.47656C7.48459 7.47645 7.31052 7.56111 7.20472 7.7177Z" fill="url(#paint2_linear_5_1928)"/>
<path d="M15.3242 14.1128C15.0963 14.3114 15.073 14.6587 15.2718 14.8858L18.2577 18.2936C18.4564 18.5207 18.8053 18.5442 19.033 18.3457C19.2606 18.1479 19.284 17.8 19.0854 17.5729L16.0995 14.1652C15.9912 14.0417 15.8385 13.9785 15.685 13.9785C15.5567 13.9785 15.4278 14.0227 15.3242 14.1128Z" fill="url(#paint3_linear_5_1928)"/>
<path d="M14.7918 12.1896C14.7449 12.4874 14.951 12.769 15.2496 12.8152L19.7342 13.5145C20.0328 13.5612 20.3152 13.3558 20.3619 13.0582C20.4086 12.7604 20.2028 12.4788 19.9041 12.4323L15.4194 11.7332C15.3909 11.7287 15.3626 11.7266 15.3345 11.7266C15.0691 11.7266 14.8341 11.9204 14.7918 12.1896Z" fill="url(#paint4_linear_5_1928)"/>
<path d="M18.1995 7.5694L14.4352 10.0981C14.1846 10.2664 14.1177 10.6083 14.2868 10.8582V10.8584C14.4556 11.1082 14.7987 11.1747 15.0494 11.0065L18.8134 8.47782C19.0642 8.30964 19.1309 7.96759 18.9623 7.7177C18.8564 7.56111 18.6822 7.47645 18.5053 7.47656C18.3997 7.47656 18.2931 7.50656 18.1995 7.5694Z" fill="url(#paint5_linear_5_1928)"/>
<path d="M8.1543 13.7977C8.1543 17.2995 10.4294 20.1559 13.2189 20.1559C16.0086 20.1559 18.2835 17.2995 18.2835 13.7977C18.2835 10.2955 16.0086 7.43945 13.2189 7.43945C10.4294 7.43945 8.1543 10.2955 8.1543 13.7977Z" fill="url(#paint6_radial_5_1928)"/>
<path d="M16.7282 9.35563C15.8283 8.22642 14.5871 7.52778 13.2174 7.52778C11.8477 7.52778 10.6065 8.22642 9.70671 9.35563C8.80235 10.491 8.24319 12.061 8.24319 13.7959C8.24319 15.5309 8.80235 17.1005 9.70671 18.2359C10.6065 19.3654 11.8477 20.0639 13.2174 20.0639C14.5871 20.0639 15.8283 19.3654 16.7282 18.2359C17.6323 17.1005 18.1916 15.5309 18.1916 13.7959C18.1916 12.061 17.6323 10.491 16.7282 9.35563ZM13.2174 7.34766C14.6449 7.34766 15.9358 8.07243 16.869 9.24404C17.7978 10.41 18.3721 12.0193 18.3721 13.7959C18.3721 15.5722 17.7978 17.1819 16.869 18.3478C15.9358 19.5191 14.6449 20.2439 13.2174 20.2439C11.79 20.2439 10.499 19.5191 9.56581 18.3478C8.63705 17.1819 8.0625 15.5722 8.0625 13.7959C8.0625 12.0193 8.63705 10.41 9.56581 9.24404C10.499 8.07243 11.79 7.34766 13.2174 7.34766Z" fill="#830E09"/>
<path d="M8.92773 10.2628H17.4357C16.9012 7.66287 15.1989 5.76172 13.1816 5.76172C11.1642 5.76172 9.46205 7.66287 8.92773 10.2628Z" fill="url(#paint7_radial_5_1928)"/>
<path d="M15.7923 7.07275C15.0656 6.30377 14.1628 5.84808 13.1845 5.84808C12.2065 5.84808 11.3034 6.30377 10.5768 7.07275C9.85167 7.83992 9.30151 8.91902 9.0262 10.169H17.3431C17.0676 8.91902 16.5173 7.83992 15.7923 7.07275ZM13.1845 5.66797C14.1998 5.66797 15.1362 6.14025 15.8896 6.93707C16.6586 7.75071 17.236 8.90368 17.5102 10.2372L17.5331 10.3489H17.4385H8.93067H8.83594L8.85874 10.2372C9.13302 8.90368 9.71053 7.75071 10.4798 6.93707C11.2328 6.14025 12.1695 5.66797 13.1845 5.66797Z" fill="#231F20"/>
<path d="M13.125 10.3516H13.3049V20.1737H13.125V10.3516Z" fill="#830E09"/>
<path d="M9.51367 9.87176H10.8514C10.8514 9.87176 10.7799 8.34788 11.4009 7.34776C12.0221 6.34729 12.8343 6.10922 12.8343 6.10922C12.8343 6.10922 10.6364 6.03797 9.51367 9.87176Z" fill="#A6A8AB" fillOpacity="0.603922"/>
<path d="M11.2001 12.7319C11.5824 12.7319 11.8941 12.4208 11.8941 12.0398C11.8941 11.6588 11.5824 11.3477 11.2001 11.3477C10.8176 11.3477 10.5059 11.6588 10.5059 12.0398C10.5059 12.4208 10.8176 12.7319 11.2001 12.7319Z" fill="#FFCA11"/>
<path d="M11.6235 11.6141C11.5143 11.5054 11.3632 11.4379 11.1964 11.4379C11.0298 11.4379 10.8786 11.5054 10.7696 11.6141C10.6602 11.7229 10.5925 11.8735 10.5925 12.0398C10.5925 12.2059 10.6602 12.3566 10.7696 12.4654C10.8786 12.5743 11.0298 12.6418 11.1964 12.6418C11.3632 12.6418 11.5143 12.5743 11.6235 12.4654C11.7327 12.3566 11.8004 12.2059 11.8004 12.0398C11.8004 11.8735 11.7327 11.7229 11.6235 11.6141ZM11.1964 11.2578C11.4131 11.2578 11.6092 11.3454 11.7511 11.4868C11.893 11.6284 11.9811 11.824 11.9811 12.0398C11.9811 12.2556 11.893 12.4512 11.7511 12.5927C11.6092 12.7343 11.4131 12.8216 11.1964 12.8216C10.9797 12.8216 10.7835 12.7343 10.6417 12.5927C10.4999 12.4512 10.4121 12.2556 10.4121 12.0398C10.4121 11.824 10.4999 11.6284 10.6417 11.4868C10.7835 11.3454 10.9797 11.2578 11.1964 11.2578Z" fill="#A31F16"/>
<path d="M9.56317 15.6358C9.94563 15.6358 10.2572 15.325 10.2572 14.9437C10.2572 14.5627 9.94563 14.252 9.56317 14.252C9.18093 14.252 8.86914 14.5627 8.86914 14.9437C8.86914 15.325 9.18093 15.6358 9.56317 15.6358Z" fill="#FFCA11"/>
<path d="M9.9926 14.5166C9.8835 14.4078 9.73223 14.3403 9.56579 14.3403C9.39902 14.3403 9.24808 14.4078 9.13876 14.5166C9.02954 14.6256 8.96182 14.7758 8.96182 14.9421C8.96182 15.1082 9.02954 15.2591 9.13876 15.3678C9.24808 15.4769 9.39902 15.5441 9.56579 15.5441C9.73223 15.5441 9.8835 15.4769 9.9926 15.3678C10.102 15.2591 10.1698 15.1082 10.1698 14.9421C10.1698 14.7758 10.102 14.6256 9.9926 14.5166ZM9.56579 14.1602C9.78227 14.1602 9.97858 14.2477 10.1205 14.3895C10.2623 14.5309 10.3502 14.7263 10.3502 14.9421C10.3502 15.1582 10.2623 15.3536 10.1205 15.4951C9.97858 15.6366 9.78227 15.7242 9.56579 15.7242C9.34908 15.7242 9.153 15.6366 9.01119 15.4951C8.86926 15.3536 8.78125 15.1582 8.78125 14.9421C8.78125 14.7263 8.86926 14.5309 9.01119 14.3895C9.153 14.2477 9.34908 14.1602 9.56579 14.1602Z" fill="#A31F16"/>
<path d="M11.9284 18.2785C12.3106 18.2785 12.6224 17.9677 12.6224 17.5863C12.6224 17.2054 12.3106 16.8945 11.9284 16.8945C11.5459 16.8945 11.2344 17.2054 11.2344 17.5863C11.2344 17.9677 11.5459 18.2785 11.9284 18.2785Z" fill="#FFCA11"/>
<path d="M12.3539 17.1574C12.2449 17.0484 12.0937 16.9809 11.9269 16.9809C11.7602 16.9809 11.6094 17.0484 11.5001 17.1574C11.3909 17.2662 11.3232 17.4168 11.3232 17.5827C11.3232 17.7489 11.3909 17.8997 11.5001 18.0084C11.6094 18.1175 11.7602 18.185 11.9269 18.185C12.0937 18.185 12.2449 18.1175 12.3539 18.0084C12.4631 17.8997 12.5309 17.7489 12.5309 17.5827C12.5309 17.4168 12.4631 17.2662 12.3539 17.1574ZM11.9269 16.8008C12.1434 16.8008 12.3397 16.8887 12.4818 17.03C12.6236 17.1714 12.7115 17.3669 12.7115 17.5827C12.7115 17.7988 12.6236 17.9941 12.4818 18.1359C12.3397 18.2773 12.1434 18.3649 11.9269 18.3649C11.7104 18.3649 11.5143 18.2773 11.3725 18.1359C11.2304 17.9941 11.1426 17.7988 11.1426 17.5827C11.1426 17.3669 11.2304 17.1714 11.3725 17.03C11.5143 16.8887 11.7104 16.8008 11.9269 16.8008Z" fill="#A31F16"/>
<path d="M15.3134 12.7319C15.6957 12.7319 16.0076 12.4208 16.0076 12.0398C16.0076 11.6588 15.6957 11.3477 15.3134 11.3477C14.931 11.3477 14.6191 11.6588 14.6191 12.0398C14.6191 12.4208 14.931 12.7319 15.3134 12.7319Z" fill="#FFCA11"/>
<path d="M14.885 11.6141C14.9943 11.5054 15.1456 11.4379 15.3121 11.4379C15.4788 11.4379 15.6299 11.5054 15.739 11.6141C15.8483 11.7229 15.916 11.8735 15.916 12.0398C15.916 12.2059 15.8483 12.3566 15.739 12.4654C15.6299 12.5743 15.4788 12.6418 15.3121 12.6418C15.1456 12.6418 14.9943 12.5743 14.885 12.4654C14.7758 12.3566 14.7081 12.2059 14.7081 12.0398C14.7081 11.8735 14.7758 11.7229 14.885 11.6141ZM15.3121 11.2578C15.0956 11.2578 14.8993 11.3454 14.7574 11.4868C14.6155 11.6284 14.5273 11.824 14.5273 12.0398C14.5273 12.2556 14.6155 12.4512 14.7574 12.5927C14.8993 12.7343 15.0956 12.8216 15.3121 12.8216C15.5287 12.8216 15.7248 12.7343 15.8668 12.5927C16.0085 12.4512 16.0967 12.2556 16.0967 12.0398C16.0967 11.824 16.0085 11.6284 15.8668 11.4868C15.7248 11.3454 15.5287 11.2578 15.3121 11.2578Z" fill="#A31F16"/>
<path d="M16.9463 15.6358C17.3286 15.6358 17.6404 15.325 17.6404 14.9437C17.6404 14.5627 17.3286 14.252 16.9463 14.252C16.5639 14.252 16.252 14.5627 16.252 14.9437C16.252 15.325 16.5639 15.6358 16.9463 15.6358Z" fill="#FFCA11"/>
<path d="M16.5237 14.5166C16.6326 14.4078 16.7839 14.3403 16.9506 14.3403C17.1171 14.3403 17.2682 14.4078 17.3774 14.5166C17.4865 14.6256 17.5543 14.7758 17.5543 14.9421C17.5543 15.1082 17.4865 15.2591 17.3774 15.3678C17.2682 15.4769 17.1171 15.5441 16.9506 15.5441C16.7839 15.5441 16.6326 15.4769 16.5237 15.3678C16.414 15.2591 16.3463 15.1082 16.3463 14.9421C16.3463 14.7758 16.414 14.6256 16.5237 14.5166ZM16.9506 14.1602C16.734 14.1602 16.5375 14.2477 16.3958 14.3895C16.2539 14.5309 16.166 14.7263 16.166 14.9421C16.166 15.1582 16.2539 15.3536 16.3958 15.4951C16.5375 15.6366 16.734 15.7242 16.9506 15.7242C17.1671 15.7242 17.3633 15.6366 17.505 15.4951C17.6469 15.3536 17.735 15.1582 17.735 14.9421C17.735 14.7263 17.6469 14.5309 17.505 14.3895C17.3633 14.2477 17.1671 14.1602 16.9506 14.1602Z" fill="#A31F16"/>
<path d="M14.5849 18.2785C14.9673 18.2785 15.2792 17.9677 15.2792 17.5863C15.2792 17.2054 14.9673 16.8945 14.5849 16.8945C14.2026 16.8945 13.8906 17.2054 13.8906 17.5863C13.8906 17.9677 14.2026 18.2785 14.5849 18.2785Z" fill="#FFCA11"/>
<path d="M14.1562 17.1574C14.2652 17.0484 14.4165 16.9809 14.5831 16.9809C14.7499 16.9809 14.9007 17.0484 15.0102 17.1574C15.1192 17.2662 15.1869 17.4168 15.1869 17.5827C15.1869 17.7489 15.1192 17.8997 15.0102 18.0084C14.9007 18.1175 14.7499 18.185 14.5831 18.185C14.4165 18.185 14.2652 18.1175 14.1562 18.0084C14.0469 17.8997 13.9792 17.7489 13.9792 17.5827C13.9792 17.4168 14.0469 17.2662 14.1562 17.1574ZM14.5831 16.8008C14.3668 16.8008 14.1704 16.8887 14.0283 17.03C13.8865 17.1714 13.7988 17.3669 13.7988 17.5827C13.7988 17.7988 13.8865 17.9941 14.0283 18.1359C14.1704 18.2773 14.3668 18.3649 14.5831 18.3649C14.7998 18.3649 14.9958 18.2773 15.1377 18.1359C15.2797 17.9941 15.3676 17.7988 15.3676 17.5827C15.3676 17.3669 15.2797 17.1714 15.1377 17.03C14.9958 16.8887 14.7998 16.8008 14.5831 16.8008Z" fill="#A31F16"/>
<path d="M14.0648 9.87176H12.7271C12.7271 9.87176 12.7987 8.34788 12.1776 7.34776C11.5564 6.34729 10.7441 6.10922 10.7441 6.10922C10.7441 6.10922 12.942 6.03797 14.0648 9.87176Z" fill="#A6A8AB" fillOpacity="0.603922"/>
<path d="M12.6335 19.7879V18.3106C12.6335 18.3106 10.4996 17.4618 9.56444 15.2584C8.62931 13.0549 9.32755 10.8105 9.32755 10.8105C9.32755 10.8105 8.31216 14.0305 9.08531 15.9811C10.1494 18.6658 12.6335 19.7879 12.6335 19.7879Z" fill="white" fillOpacity="0.352941"/>
<path d="M13.7715 19.8973V18.42C13.7715 18.42 15.9241 17.5713 16.8593 15.3678C17.7944 13.1643 17.1055 10.9199 17.1055 10.9199C17.1055 10.9199 18.1067 14.1398 17.3337 16.0904C16.2697 18.7751 13.7715 19.8973 13.7715 19.8973Z" fill="white" fillOpacity="0.352941"/>
<defs>
<linearGradient id="paint0_linear_5_1928" x1="16.1576" y1="11.92" x2="17.1338" y2="10.7867" gradientUnits="userSpaceOnUse">
<stop stopColor="#6C6D6F"/>
<stop offset="1" stopColor="#221E1F"/>
</linearGradient>
<linearGradient id="paint1_linear_5_1928" x1="9.62085" y1="11.8384" x2="11.2861" y2="11.1103" gradientUnits="userSpaceOnUse">
<stop stopColor="#6C6D6F"/>
<stop offset="1" stopColor="#221E1F"/>
</linearGradient>
<linearGradient id="paint2_linear_5_1928" x1="5.73158" y1="9.26976" x2="7.16621" y2="10.6179" gradientUnits="userSpaceOnUse">
<stop stopColor="#6C6D6F"/>
<stop offset="1" stopColor="#221E1F"/>
</linearGradient>
<linearGradient id="paint3_linear_5_1928" x1="6.97601" y1="-11.204" x2="6.02275" y2="-12.3514" gradientUnits="userSpaceOnUse">
<stop stopColor="#6C6D6F"/>
<stop offset="0.613174" stopColor="#221E1F"/>
<stop offset="1" stopColor="#221E1F"/>
</linearGradient>
<linearGradient id="paint4_linear_5_1928" x1="-3.35708" y1="-6.75101" x2="-5.01778" y2="-7.56933" gradientUnits="userSpaceOnUse">
<stop stopColor="#6C6D6F"/>
<stop offset="1" stopColor="#221E1F"/>
</linearGradient>
<linearGradient id="paint5_linear_5_1928" x1="-5.09721" y1="12.2869" x2="-6.54949" y2="13.6016" gradientUnits="userSpaceOnUse">
<stop stopColor="#6C6D6F"/>
<stop offset="1" stopColor="#221E1F"/>
</linearGradient>
<radialGradient id="paint6_radial_5_1928" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.2189 13.7977) scale(5.02145 6.30405)">
<stop stopColor="#EF5022"/>
<stop offset="0.580002" stopColor="#EF4822"/>
<stop offset="0.763687" stopColor="#A31E15"/>
<stop offset="1" stopColor="#A31E15"/>
</radialGradient>
<radialGradient id="paint7_radial_5_1928" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.1817 8.01221) scale(6.24708 3.30495)">
<stop stopColor="#A6A8AB"/>
<stop offset="1" stopColor="#57585A"/>
</radialGradient>
</defs>
</svg>

  )
}

export default Coccinelle