import React from 'react'

const InstructorIcon = ({className, color}: { className?: string, color?: string }) => {
    return (
        <svg className={className} width="32" height="32" viewBox="0 0 63 50" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.8204 34.3846C20.5908 34.3846 20.3612 34.4183 20.1422 34.4893C18.8974 34.8937 17.5882 35.1531 16.2099 35.1531C14.8315 35.1531 13.5223 34.8937 12.2765 34.4893C12.0575 34.4183 11.8289 34.3846 11.5993 34.3846C5.63832 34.3846 0.809766 39.2334 0.841464 45.2021C0.854911 47.7244 2.92868 49.753 5.45198 49.753H26.9677C29.491 49.753 31.5648 47.7244 31.5782 45.2021C31.6099 39.2334 26.7814 34.3846 20.8204 34.3846ZM16.2099 31.311C21.3026 31.311 25.4309 27.1826 25.4309 22.0899C25.4309 16.9972 21.3026 12.8689 16.2099 12.8689C11.1172 12.8689 6.98882 16.9972 6.98882 22.0899C6.98882 27.1826 11.1172 31.311 16.2099 31.311ZM57.7045 0.574219H20.8204C18.2779 0.574219 16.2099 2.71138 16.2099 5.33745V9.79524C18.4594 9.79524 20.5418 10.4465 22.3572 11.505V6.72157H56.1677V34.3846H50.0203V28.2373H37.7256V34.3846H30.4026C32.2372 35.9878 33.5838 38.1048 34.2149 40.532H57.7045C60.247 40.532 62.315 38.3948 62.315 35.7688V5.33745C62.315 2.71138 60.247 0.574219 57.7045 0.574219Z"
                fill={color}/>
        </svg>
    )
}

export default InstructorIcon