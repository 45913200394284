import React from 'react'

const Arrow = ({color, className, onClick}: { color?: string, className?: string, onClick?: any }) => {
    return (
        <svg onClick={onClick} className={className} width="20" height="37" viewBox="0 0 20 37" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.4951 36.7731C18.3159 36.7732 18.1383 36.738 17.9726 36.6694C17.807 36.6009 17.6564 36.5004 17.5296 36.3736L0.704111 19.5472C0.448205 19.2912 0.304445 18.9441 0.304445 18.5821C0.304445 18.2202 0.448205 17.873 0.704111 17.617L17.5296 0.790635C17.6563 0.663839 17.8068 0.563247 17.9724 0.494602C18.138 0.425957 18.3155 0.390605 18.4948 0.390562C18.6741 0.39052 18.8516 0.425789 19.0173 0.494356C19.1829 0.562922 19.3334 0.663444 19.4602 0.79018C19.587 0.916917 19.6876 1.06739 19.7563 1.233C19.8249 1.39861 19.8603 1.57612 19.8603 1.7554C19.8603 1.93467 19.8251 2.1122 19.7565 2.27785C19.6879 2.44349 19.5874 2.59401 19.4607 2.7208L3.59982 18.5817L19.4607 34.4425C19.6517 34.6335 19.7818 34.8767 19.8345 35.1416C19.8873 35.4065 19.8602 35.681 19.7569 35.9305C19.6535 36.1801 19.4784 36.3933 19.2538 36.5433C19.0293 36.6933 18.7652 36.7732 18.4951 36.7731Z"
                fill={color}/>
        </svg>
    )
}

export default Arrow