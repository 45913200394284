import React from 'react'

const Decoration = () => {
  return (
    <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5071 9.99023C13.9016 9.99023 13.165 9.61686 12.2971 8.87012C11.4091 8.14355 10.9651 7.10417 10.9651 5.75195C10.9651 4.41992 11.4596 3.19889 12.4485 2.08887C13.4374 0.97884 14.6181 0.423827 15.9905 0.423827C17.3629 0.423828 18.4931 0.817382 19.3811 1.60449C20.2691 2.3916 20.7131 3.65299 20.7131 5.38867C20.7131 7.12435 19.9159 9.1123 18.3215 11.3525C16.7069 13.5928 14.8603 15.0358 12.7815 15.6816C12.0953 15.3184 11.7219 14.7633 11.6614 14.0166C12.4081 13.4313 13.064 12.7048 13.6292 11.8369C14.1741 10.9893 14.4667 10.3737 14.5071 9.99023ZM3.66919 9.99023C3.06372 9.99023 2.32707 9.61686 1.45923 8.87012C0.571208 8.14355 0.127198 7.10416 0.127198 5.75195C0.127198 4.41992 0.621664 3.19889 1.6106 2.08887C2.59953 0.978839 3.78019 0.423826 5.15259 0.423827C6.52499 0.423827 7.65519 0.817381 8.54321 1.60449C9.41105 2.3916 9.84497 3.47135 9.84497 4.84375C9.84497 6.21614 9.49178 7.64909 8.7854 9.14258C8.05884 10.6562 7.08 12.0186 5.84888 13.2295C4.61776 14.4404 3.30591 15.2578 1.91333 15.6816C1.22713 15.2982 0.85376 14.7432 0.793213 14.0166C1.56014 13.4313 2.22616 12.7048 2.79126 11.8369C3.33618 10.9893 3.62883 10.3737 3.66919 9.99023Z" fill="#3869F1"/>
    </svg>
    
  )
}

export default Decoration