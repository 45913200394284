import React, {useEffect, useState} from 'react'
import {Community, FirstView, GetStarted, PopularJobs, QuickGuide} from './components'
import Events from "../../common/components/Events/Events";
import Customers from "../../common/components/Customers/Costumers";
import PressOpinion from "../../common/components/PressOpinion/PressOpinion";
import OfferPopup from "./components/OfferPopup/OfferPopup";

const ForCompanies = () => {
    const [showOfferPopup, setShowOfferPopup] = useState<boolean>(false)

    // useEffect(() => {
    //     setTimeout(function () {
    //         setShowOfferPopup(true)
    //     }, 4000)
    //     return () => {
    //     };
    // }, [])

    return (
        <div>
            <FirstView/>
            <PopularJobs/>
            <QuickGuide/>
            <Community/>
            <GetStarted/>
            <Events/>
            <Customers/>
            <PressOpinion/>
            {/*<OfferPopup visible={showOfferPopup} onClose={() => setShowOfferPopup(false)}/>*/}
        </div>
    )
}
export default ForCompanies
