function CloseSquare({className, onClick, color}: { className?: string, onClick?: () => void, color?: string }) {
    const clr = color ?? '#7F8A9D'

    return (
        <svg
            className={className}
            onClick={onClick}
            width="32" height="32" viewBox="0 0 48 48" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1727_73)">
                <path d="M18.3398 29.6601L29.6598 18.3401" stroke={clr} strokeWidth="3" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M29.6598 29.6601L18.3398 18.3401" stroke={clr} strokeWidth="3" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M18 44H30C40 44 44 40 44 30V18C44 8 40 4 30 4H18C8 4 4 8 4 18V30C4 40 8 44 18 44Z"
                      stroke={clr} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_1727_73">
                    <rect width="48" height="48" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default CloseSquare
