import React from 'react'
import {FirstView, FutureView, MasterClass, OurProcess, PopularJobs, Process, ShareOnline} from './components'
import Events from "../../common/components/Events/Events";
import Customers from "../../common/components/Customers/Costumers";
import PressOpinion from "../../common/components/PressOpinion/PressOpinion";


const ForDevelopers = () => {
    return (
        <div>
            <FirstView/>
            <PopularJobs/>
            <Process/>
            <MasterClass/>
            <FutureView/>
            <OurProcess/>
            <ShareOnline/>
            <Events/>
            <Customers/>
            <PressOpinion/>
        </div>
    )
}
export default ForDevelopers
