import React from 'react'
import pic from './googlePlayBtn.png'

const GooglePlayButton = ({href}: { href?: string }) => {
    return (
        <a href={href} target='_blank' rel="noreferrer">
            <img className='w-[175px] md:w-[107px] md:h-[30px] h-[50px]' src={pic} alt=""/>
        </a>
    )
}

export default GooglePlayButton