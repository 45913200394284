import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import {useTranslation} from "react-i18next";

const ArticleDetail = () => {

    const params = useParams()
    const [article, setArticle] = useState<any>()

    const imgRef = useRef<HTMLImageElement>(null)
    const [bgHeight, setBgHeight] = useState<string>()
    const {t} = useTranslation()
    // calc y offset
    const getOffset = () => {
        const h = imgRef.current?.height
        const y = imgRef.current?.offsetTop

        if (h && y) {
            setBgHeight(`h-[${y + h}px]`)
        }

    }



    useEffect(() => {
        axios.get(`https://redev-api-demo.mimteam.net/landing/blog/${params.id}`)
            .then((res) => {
                setArticle(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [params.id])

    useEffect(() => {
        getOffset()
        window.scrollTo(0, -100)
        window.addEventListener("resize", getOffset)

        return () => {
            window.removeEventListener("resize", getOffset)
        }
    }, [])


    return (
        <div id='article' className='relative'>
            <div className={`bg-headArticleBg absolute -left-96 -right-96 -top-[130px] -z-50 rounded-b-full h-[95vh]`}/>
            <div className='max-w-7xl sm:max-w-full sm:px-6 mx-auto'>
                <div className='flex flex-col gap-y-4 z-10 mt-10 sm:mt-20 lg:mt-20 lg:mx-4'>
                    <p className='w-full text-xl sm:text-lg leading-8 text-[#3869F1] tracking-[0.5rem]'>{t<string>('BlogArticle')}</p>
                    <div id='head' className='grid grid-cols-12 sm:grid-cols-1 text-start gap-x-4'>
                        <div className='col-span-6 sm:order-3 sm:mt-2'>
                            <img ref={imgRef}
                                 className='w-full aspect-video rounded-[12px] sm:rounded-[4px] object-cover lg:rounded-2xl'
                                 src={article?.coverUrl} alt=""/>
                        </div>
                        <div className='col-span-6 flex flex-col justify-between items-start'>
                            <div>
                                <h1 className='font-bold text-2xl sm:text-2xl sm:leading-10 leading-[60px] '>{article?.title}</h1>
                                <p className='text-lg sm:text-lg sm:leading-8 leading-10 text-[#8A8A8A] '>{article?.subtitle}</p>
                            </div>
                            <hr className='minlg:hidden w-full my-4 h-[2px] bg-black opacity-10'/>
                            <p className='text-sm sm:text-sm sm:leading-6 leading-10'>{article?.publishedAtStr ?? "Today"} | {" "}
                                {t<string>('Author')}: {article?.author ?? "----"}</p>
                        </div>
                    </div>
                    <div className="text-gray-700 text-lg sm:text-md leading-8 minmd:leading-loose"
                         dangerouslySetInnerHTML={{__html: article?.content}}/>
                </div>
            </div>
        </div>
    )
}

export default ArticleDetail
