import React from 'react'

const User = ({ color, className }: { color?: string, className?: string}) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 25.1464H22.9867C22.4624 25.1443 21.9428 25.2456 21.4577 25.4447C20.9726 25.6437 20.5317 25.9366 20.16 26.3064L17.88 28.5598C17.3761 29.0534 16.6988 29.3299 15.9933 29.3299C15.2879 29.3299 14.6106 29.0534 14.1067 28.5598L11.8267 26.3064C11.0751 25.5609 10.0586 25.1438 9 25.1464H8C7.47724 25.1494 6.95902 25.0493 6.47498 24.8518C5.99094 24.6544 5.55057 24.3634 5.17906 23.9956C4.80756 23.6279 4.5122 23.1904 4.30989 22.7084C4.10757 22.2264 4.00227 21.7092 4 21.1864V6.63975C4.00227 6.11699 4.10757 5.5998 4.30989 5.11777C4.5122 4.63574 4.80756 4.19832 5.17906 3.83052C5.55057 3.46273 5.99094 3.17179 6.47498 2.97433C6.95902 2.77687 7.47724 2.67677 8 2.67975H24C24.5228 2.67677 25.041 2.77687 25.525 2.97433C26.0091 3.17179 26.4494 3.46273 26.8209 3.83052C27.1924 4.19832 27.4878 4.63574 27.6901 5.11777C27.8924 5.5998 27.9977 6.11699 28 6.63975V21.1864C27.9929 22.2414 27.568 23.2506 26.8182 23.9929C26.0685 24.7352 25.055 25.15 24 25.1464V25.1464Z" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.1079 10.2278C19.1079 10.8422 18.9257 11.4428 18.5843 11.9537C18.2429 12.4646 17.7577 12.8628 17.1901 13.0979C16.6224 13.3331 15.9978 13.3946 15.3951 13.2747C14.7925 13.1549 14.2389 12.859 13.8045 12.4245C13.37 11.99 13.0741 11.4365 12.9542 10.8338C12.8344 10.2312 12.8959 9.60656 13.131 9.03889C13.3661 8.47122 13.7643 7.98603 14.2752 7.64466C14.7861 7.3033 15.3868 7.12109 16.0012 7.12109C16.8251 7.12109 17.6153 7.4484 18.1979 8.03102C18.7806 8.61363 19.1079 9.40382 19.1079 10.2278Z" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.3346 20.8799C21.3346 18.4799 18.948 16.5332 16.0013 16.5332C13.0546 16.5332 10.668 18.4799 10.668 20.8799" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default User