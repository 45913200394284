import React, {useEffect, useRef} from "react";
import avatar from "../../../../assets/avatar.png";
import seconavatar from "../../../../assets/seconavatar.png";
import thirdavatar from "../../../../assets/thirdavatar.png";
import Vector from "../../../../assets/img/Vector";
import {useTranslation} from 'react-i18next'

import vector from "../../../../assets/vector.png";
import Bottomvect from "../../../../assets/Bottomvect.png";
import top from "../../../../assets/top.png";
import Cardvect from "../../../../assets/Cardvect.png"

import first from "../../../../assets/first.png";
import second from "../../../../assets/second.png";
import third from "../../../../assets/third.png";
import CodeBracket from "../../../../assets/icons/CodeBracket";
import ForJob from "../../../../assets/icons/ForJob";
import Star from "../../../../assets/icons/Star";
import circ from "../../../../assets/circ.png"
import {motion, useAnimation, useInView} from "framer-motion";


const Cards = () => {
    const ref = useRef(null)
    const inView = useInView(ref);
    const {t} = useTranslation()

    const controls = useAnimation();

    const container = {
        hidden: {opacity: 1, scale: 0},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2
            }
        }
    }

    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1
        }
    };

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        } else if (!inView) {
            controls.start("hidden")
        }
    }, [controls, inView]);


    return (
        <div className="relative w-[27rem] sm:w-[85%] mr-32 lg:mr-0 lg:ml-56 sm:ml-0 minmd:top-8">
            <motion.div ref={ref}
                        animate={controls}
                        variants={container}
                        initial="hidden"
                        className="md:ml-16 md:w-full mb-10 rounded-3xl relative h-[27rem] sm:h-[23rem] md:mt-10 sm:mt-0 bg-[#dbeafe]  absolute top-10  ">
                <div className="">
                    <div className="flex relative">
                        <motion.div
                            variants={item}
                            className="flex bg-white items-center justify-center md:ml-4 gap-x-2 sm:gap-x-1 rounded-[10px] py-2 sm:px-1 px-2 absolute sm:-left-24  top-14 left-7 ">
                            <Vector color="green"/>
                            <p className="font-semibold text-sm md:text-[10px]"> {t<string>("profilecompl")}</p>
                        </motion.div>
                        <motion.div
                            variants={item}

                            className="absolute top-5 sm:-left-0 sm:top-7 right-1/3">
                            <img className=" w-24 h-8  " src={top}/>
                        </motion.div>
                        <motion.div variants={item} className="absolute top-1  sm:left-4 sm:top-0 left-[45%]">
                            <p className='text-red px-2 sm:py-2 text-[12px] text-center font-light  bg-white rounded-[7px] rotate-12 py-2 '>
                                <span className="text-[#FF5601] font-semibold"> AI</span> Screening</p>
                        </motion.div>
                    </div>
                    <motion.div
                        variants={item}
                        className="outline outline-[#e2e8f0] outline-1 shadow-md shadow-slate-100 rounded-[20px] md:w-[220px] minmd:left-52 w-[19rem] bg-white h-[16rem] sm:h-[12rem]   drop-shadow-xl   absolute sm:left-14 sm:ml-10  left-64 top-14  ">
                        <div className=''>
                            <h5 className="text-[#FF5601] font-semibold pt-4 pl-6 text-sm md:text-xs ">
                                {t<string>("jobsfound")}
                            </h5>
                            <div className="mt-2 h-px mb-4 mx-2 bg-[#d1d5db] "/>
                            <div className="space-y-4">
                                <div className="flex pl-6 space-x-4 sm:space-x-2">
                                    <img
                                        className="h-12 w-12 sm:h-8 sm:w-8 rounded-full"
                                        src={first}
                                        alt=""
                                    />
                                    <div>
                                        <p className="font-semibold text-sm sm:text-xs ">
                                            {" "}
                                            {t<string>('AndroidDeveloper')}
                                        </p>
                                        <p className="text-sm  text-[#9B9B9B] sm:text-xs ">
                                            Germany
                                        </p>
                                    </div>
                                </div>
                                <div className="flex pl-6 space-x-4 sm:space-x-2">
                                    <img
                                        className="  h-12 w-12 sm:h-8 sm:w-8 rounded-full"
                                        src={second}
                                        alt=""
                                    />
                                    <div>
                                        <p className="font-semibold text-sm  sm:text-xs ">
                                            {" "}
                                            {t<string>('MobileAppDeveloper')}
                                        </p>
                                        <p className="text-sm  text-[#9B9B9B] sm:text-xs ">
                                            France
                                        </p>
                                    </div>
                                </div>
                                <div className="flex pl-6 space-x-4 sm:space-x-2">
                                    <img
                                        className=" sm:h-8 sm:w-8   h-12 w-12 rounded-full "
                                        src={third}
                                        alt=""
                                    />
                                    <div>
                                        <p className="font-semibold text-sm  sm:text-xs ">
                                            {" "}
                                            {t<string>('KotlinAndroidDeveloper')}
                                        </p>
                                        <p className=" text-sm  text-[#9B9B9B] sm:text-xs ">
                                            Austria
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div variants={item} className="absolute bottom-16 right-20 sm:bottom-12">
                        <img className='h-12 w-4 ' src={Bottomvect} alt=""/>
                    </motion.div>
                    <motion.div variants={item}
                                className="flex bg-white justify-center items-center space-x-2 md:text-xs text-sm font-semibold rounded-xl w-[10rem] sm:w-[8rem] absolute bottom-1 py-4 sm:py-2 sm:right-3 -right-8">
                        <ForJob/> <p className='w-2/3'>{t<string>('jobapp')}</p>
                    </motion.div>

                    <motion.div variants={item} className="absolute bottom-6 right-36 sm:right-36 sm:bottom-4"><img
                        className='h-4 w-8 ' src={vector} alt=""/></motion.div>

                    <div className="absolute bottom-3 left-4 sm:-left-5 sm:bottom-1 ">
                        <motion.div variants={item}
                                    className="flex bg-white justify-center items-center space-x-2   rounded-xl py-2 sm:w-[9rem] w-[14rem]">
                            <CodeBracket/>
                            <div className="text-sm  md:text-xs font-semibold ">{t<string>("CodeChallenge")}</div>
                        </motion.div>
                    </div>
                    <motion.div className='absolute  bottom-20 left-24 sm:left-0 sm:bottom-16' variants={item}><img
                        className='h-4 w-8 rotate-90'
                        src={vector} alt=""/>
                    </motion.div>

                    <motion.div
                        variants={item}
                        className="absolute top-64 sm:top-56 sm:-left-32">
                        <div
                            className=" w-32 md:w-28 py-4 text-center md:text-xs ml-16 tracking-wide bg-[#009E78] text-white rounded-[10px] ">
                            {t<string>("qualified")}
                        </div>
                    </motion.div>
                    <motion.div
                        variants={item}
                        className="absolute bottom-36 -left-12 sm:hidden">
                        <motion.img variants={item} src={Cardvect}/>
                    </motion.div>
                </div>


                <motion.div variants={item} className='absolute top-1/2 -left-9 sm:hidden'>
                    <p className='text-red w-24 text-sm md:text-xs text-center font-light leading-relaxed whitespace-nowrap  tracking-wide bg-white rounded-[7px] -rotate-12 py-1   '>
                        <span className="text-[#FF5601] font-semibold"> AI</span> Screening</p>

                    {/* <img className="h-20 w-[18rem]" src={vectorcard} alt=""/> */}
                </motion.div>
                <motion.div variants={item} className="absolute -bottom-10 -left-20 minsm:hidden">
                    <img src={circ} alt=""/>
                </motion.div>
                <motion.div variants={item} className="absolute -left-[80%] lg:-left-64 top-[67%] sm:top-96 sm:-left-5">
                    <div className='flex flex-row '>
                        <div className=' space-y-1 pt-4 sm:order-2 '>
                            <Star/>
                            <Star className='h-3 w-4'/>
                            <Star className='h-2 w-4'/>
                        </div>
                        <div
                            className='outline outline-[#e2e8f0] sm:order-1 outline-1 shadow-md shadow-slate-100 rounded-[20px] md:w-[220px] w-[19rem] lg:w-[16rem] bg-white h-[14rem] drop-shadow-xl   '>
                            <div>
                                <div>
                                    <h5 className='text-[#FF5601] font-semibold ml-6 pt-4 text-xs '> {t<string>("top")}    </h5>
                                    <div className='mt-2 h-px mb-4 mx-2 bg-[#d1d5db] '/>
                                    <div className='space-y-4'>
                                        <div className='flex pl-6 space-x-4'>
                                            <img className='  h-10 w-10 ' src={avatar} alt=""/>
                                            <div>
                                                <p className=' text-sm font-semibold  md:text-xs '> Cris Walker</p>
                                                <p className='text-sm  text-[#9B9B9B] md:text-xs '>Austria</p></div>
                                        </div>


                                        <div className='flex pl-6 space-x-4'>
                                            <img className='  h-10 w-10  ' src={seconavatar} alt=""/>
                                            <div>
                                                <p className='text-sm font-semibold  md:text-xs '> Mayer Permiter</p>
                                                <p className='text-sm  text-[#9B9B9B] md:text-xs '>Germany</p></div>
                                        </div>


                                        <div className='flex pl-6 space-x-4'>
                                            <img className=' h-10 w-10  ' src={thirdavatar} alt=""/>
                                            <div>
                                                <p className='text-sm font-semibold md:text-xs '> Emma Muro</p>
                                                <p className='text-sm  text-[#9B9B9B] md:text-xs '>France</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </motion.div>

        </div>
    );
};

export default Cards;
