import React from 'react'
import {useTranslation} from 'react-i18next'
import processvec from '../../../../assets/processvec.png'
import processleft from '../../../../assets/processleft.png'
import Coccinelle from '../../../../assets/icons/Coccinelle'
import Cup from '../../../../assets/icons/Cup'
import Interrogation from '../../../../assets/icons/Interrogation'
import mobilevec from "../../../../assets/mobilevec.png"
import circle from '../../../../assets/circle.png'

const OurProcess = () => {
    const {t} = useTranslation()

    return (
        <section id='process' className='max-w-7xl mx-auto'>
            <div className='minlg:mt-20 md:mt-10 relative'>
                <img className=" absolute top-[35%] left-1/2 w-4 minsm:hidden" alt="" src={mobilevec}/>
                <img className=" absolute top-[58%] left-1/2 w-4 minsm:hidden" alt="" src={mobilevec}/>
                <img className=" absolute bottom-48 left-1/2 w-4 minsm:hidden" alt="" src={mobilevec}/>

                <div className='flex flex-col minlg:space-y-8  sm:space-y-4 text-center justify-center items-center'>
                    <p className='text-[#3869F1] sm:text-xs  sm:tracking-widest'>{t<string>('OUR PROCESS')}</p>
                    <div
                        className=' text-4xl minlg:w-[35%] leading-[50px] font-semibold sm:text-2xl text-[#1B2A3E] sm:mx-4'>{t<string>('seventhTitle1')}
                        <span
                            className='text-[#3869F1]'>{t<string>('seventhTitle2')}</span> {t<string>('seventhTitle3')}
                        <span className='text-[#3869F1]'> {t<string>('seventhTitle4')}</span></div>
                    <div className="text-[#949494] sm:text-xs sm:mx-2 ">{t<string>('seventhsubtite')} </div>
                </div>

                <div
                    className='minlg:mt-12 sm:mt-10 lg:mt-10 flex justify-center sm:flex-col sm:items-center sm:gap-y-8 sm:mx-4'>
                    <div
                        className='shadow-xl shadow-[#DAE4FB]  rounded-[8px] space-y-5 w-1/3  lg:min-h-[350px]  sm:min-h-[210px] sm:max-h-[210px]   sm:w-[90%] px-8 lg:px-4 py-4 flex flex-col items-start justify-between sm:p-4 '>
                        <div className='flex flex-col gap-y-2'>
                            <div className='flex gap-x-4'>
                                <p className=" bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] aspect-square text-2xl  sm:h-[30px] sm:w-[30px] sm:text-sm font-semibold rounded-md text-[#3869F1]">1</p>
                                <h1 className='text-[20px] sm:text-sm font-semibold flex items-center text-[#3869F1]'>{t<string>('processtitle1')}</h1>
                            </div>
                            <p className='font-light tracking-wide text-justify leading-relaxed sm:text-xs text-[#424242] '> {t<string>('processtext1')}</p>
                        </div>
                        {/*<p className='underline underline-offset-4 font-semibold text-lg sm:text-xs text-[#54627B]'>*/}
                        {/*    Learn More*/}
                        {/*</p>*/}
                    </div>
                    <img className="h-4 md:h-3 lg:h-3 mt-32 sm:hidden" alt="" src={processvec}/>

                    <div
                        className='shadow-xl shadow-[#DAE4FB] rounded-[8px] space-y-5 w-1/3 h-[295px] sm:min-h-[210px] sm:max-h-[210px] sm:order-2   sm:w-[90%] lg:min-h-[350px]  px-8 lg:px-4 py-4 flex flex-col items-start justify-between sm:p-4  relative'>
                        <div className='flex flex-col gap-y-2'>
                            <div className='grid grid-cols-12 space-x-2 '>
                                <p className="col-span-2 sm:col-span-1 bg-[#DAE4FB] flex justify-center whi items-center w-[46px] h-[46px] aspect-square text-2xl sm:h-[30px] sm:w-[30px] sm:text-sm font-semibold rounded-md  text-[#3869F1]">2</p>
                                <div
                                    className="col-span-10 sm:col-span-9 flex minlg:flex-row sm:flex-row sm:justify-between sm:items-center  minlg:space-x-1 lg:flex-col sm:w-[90%] ">
                                    <h1 className='text-[20px] lg:pl-1 sm:text-sm text-[#3869F1] font-semibold flex items-center whitespace-nowrap'>{t<string>('processtitle2')}</h1>
                                    <div className='flex flex-row items-center  lg:pl-1 '>
                                        <p className='text-[11px] font-semibold flex items-center sm:flex-end'>{t<string>('Reactions')} </p>
                                        <Cup className='h-5'/>
                                        <Coccinelle className='h-5'/>
                                        <Interrogation className='h-5'/>
                                    </div>
                                </div>
                            </div>
                            <p className='font-light  text-justify  sm:text-xs leading-relaxed text-[#424242]'> {t<string>('processtext2')}</p>
                        </div>
                        {/*<p className='underline underline-offset-4 font-semibold text-lg sm:text-xs text-[#54627B]'>*/}
                        {/*    {t<string>('LearnMore')}*/}
                        {/*</p>*/}
                    </div>
                    <div>
                        <img className="absolute md:hidden lg:h-52  top-1/2" alt="" src={circle}/>
                    </div>

                </div>


                <div
                    className='flex justify-center sm:mt-0 sm:flex-col sm:gap-y-8 mt-20 sm:mt-8 sm:mx-4  sm:items-center '>
                    <div
                        className='shadow-xl shadow-[#DAE4FB] rounded-[8px] space-y-4 w-1/3 h-[295px] sm:min-h-[210px] sm:max-h-[210px] sm:order-2   sm:w-[90%] lg:min-h-[350px]  px-8 lg:px-4 py-4 flex flex-col items-start justify-between sm:p-4  relative'>
                        <div className='absolute -right-5 top-7 lg:top-5 lg:-right-8'>
                            <p className=' px-2  text-red text-sm font-light leading-relaxed drop-shadow-lg p-1 tracking-wide bg-white rounded-[7px] rotate-35 sm:text-xs'>
                                <span className="text-[#EF4444]"> AI</span> Screening</p>
                        </div>
                        <div className='flex flex-col gap-y-2'>
                            <div className='flex  space-x-20 '>
                                <div className='flex space-x-2'>
                                    <p className=" bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] aspect-square text-2xl sm:h-[30px] sm:w-[30px] sm:text-sm font-semibold rounded-md text-[#3869F1]">4</p>
                                    <h1 className='text-lg  sm:text-sm  flex items-center font-semibold text-[#3869F1]'> {t<string>('processtitle4')}</h1>
                                </div>
                            </div>
                            <p className=' font-light tracking-wide text-justify sm:text-xs leading-relaxed text-[#424242]'>{t<string>('processtext4')}</p>
                        </div>
                        {/*<p className='underline underline-offset-4 font-semibold text-lg sm:text-xs text-[#54627B]'>*/}
                        {/*    Learn More*/}
                        {/*</p>*/}
                    </div>
                    <img className="h-4 md:h-3 lg:h-3 mt-40 sm:hidden" alt="" src={processleft}/>
                    <div
                        className='shadow-xl shadow-[#DAE4FB] rounded-[8px] space-y-5 w-1/3 h-[295px] sm:min-h-[210px] sm:max-h-[210px] sm:order-2   sm:w-[90%] lg:min-h-[350px]  px-8 lg:px-4 py-4 flex flex-col items-start justify-between sm:p-4  relative'>
                        <div className='flex flex-col gap-y-2'>
                            <div className='flex space-x-2'>
                                <p className=" bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] aspect-square text-2xl sm:h-[30px] sm:w-[30px] sm:text-sm font-semibold rounded-md text-[#3869F1]">3</p>
                                <h1 className='text-lg sm:text-sm flex items-center font-semibold text-[#3869F1]'>{t<string>('processtitle3')}</h1>
                            </div>
                            <p className='font-light tracking-wide text-justify leading-relaxed sm:text-xs text-[#424242]'> {t<string>('processtext3')}</p>
                        </div>
                        {/*<p className='underline underline-offset-4 font-semibold text-lg sm:text-xs text-[#54627B]'>*/}
                        {/*    Learn More*/}
                        {/*</p>*/}
                    </div>
                </div>


            </div>
        </section>
    )
}

export default OurProcess