import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from 'swiper/react'
import Arrow from "../../../assets/img/Arrow"
import {useTranslation} from "react-i18next";

import axios from "axios";


const MobileSlider = ({images}: { images: string[] }) => {
    const [swiper, setSwiper] = useState<any>(null)
    const [activeIndex, setActiveIndex] = useState<number>(0)
    const [eventData, setEventData] = useState<any>()
    const {t, i18n} = useTranslation();

    console.log(activeIndex);

    const [isVerticalImage, setIsVerticalImage] = useState(false)

    const onImageLoad = (e: any) => {
        const h = e.currentTarget.naturalHeight ?? 0
        const w = e.currentTarget.naturalWidth ?? 0
        const ratio = w <= 0 ? 1 : (h / w)
        setIsVerticalImage(ratio > 1.2)
    }
    const onImageLoadB = (e: any) => {
        const h = e.currentTarget.naturalHeight ?? 0
        const w = e.currentTarget.naturalWidth ?? 0
        const ratio = w <= 0 ? 1 : (h / w)
        if (ratio > 1.2) {
            e.currentTarget.style.objectFit = "contain"
        } else {
            e.currentTarget.style.objectFit = "cover"
        }
    }
    useEffect(() => {
        axios
            .get("https://redev-api-demo.mimteam.net/landing/event/main")
            .then((res) => {
                setEventData(res.data);
            })
            .catch(() => {
            });
    }, []);
    if (eventData) {
        return (
            <div className='mt-6 ' id='image-slider'>
                <div className=" mt-6 mx-auto space-y-6">
                    <div className="flex flex-col  text-center  space-y-4">
                        <div className=" text-2xl font-semibold text-[#1B2A3E]">
                            ReDev <span className="text-[#3869F1]"> Events</span>
                        </div>
                        <p className="tracking-wide text-xs">
                            {t<string>('fourthText')}
                        </p>
                    </div>
                    <div className=" flex flex-col  pt-6  ">
                        <div className="px-6   w-[100%] ">
                            <h4 className="text-[#1B2A3E]  text-xl  text-center font-semibold leading-[30px]">
                                {eventData[i18n.language].title}
                            </h4>
                            <p className="text-xs  font-light text-center   leading-relaxed text-[#424242] w-[100%] ">
                                {eventData[i18n.language].subtitle}
                            </p>
                        </div>
                    </div>
                    <div className='px-[10px] mb-6 flex justify-center relative group'>
                        <div onClick={() => swiper.slideNext()}
                             className={`w-8 h-8 rounded-full absolute top-1/2 -translate-y-1/2 cursor-pointer z-50 right-4 flex justify-center items-center bg-white bg-opacity-50 rotate-180 lg:hidden invisible group-hover:visible`}>
                            <Arrow color='#3869F1' className='w-2 h-4'/>
                        </div>
                        <div onClick={() => swiper.slidePrev()}
                             className={`w-8 h-8 rounded-full absolute top-1/2 -translate-y-1/2 cursor-pointer z-50 left-4 flex justify-center items-center bg-white bg-opacity-50 lg:hidden invisible group-hover:visible`}>
                            <Arrow color='#3869F1' className='w-2 h-4'/>
                        </div>
                        <img
                            className={`mx-auto w-full rounded aspect-video ${isVerticalImage ? 'object-contain' : 'object-cover'}`}
                            src={images[activeIndex]} alt=""
                            onLoad={onImageLoad}
                        />
                    </div>
                    <div className='sm:px-0 relative'>
                        <Swiper
                            loop
                            slidesPerView={3}
                            spaceBetween={-25}
                            onSwiper={(s) => setSwiper(s)}
                            centeredSlides
                            onSlideChange={(e) => {
                                const i = e.activeIndex - 3
                                setActiveIndex(e.realIndex)
                            }}
                            breakpoints={{
                                639: {
                                    slidesPerView: 3,
                                    spaceBetween: -15,
                                    slideToClickedSlide: true
                                },
                                300: {
                                    slidesPerView: 4,
                                    spaceBetween: 0,
                                    slideToClickedSlide: true
                                },
                                1000: {
                                    slidesPerView: 3,
                                    spaceBetween: -50,
                                    slideToClickedSlide: false
                                },
                                1500: {
                                    slidesPerView: 3,
                                    spaceBetween: -10,
                                    slideToClickedSlide: false
                                }
                            }}
                        >
                            {images.map((item: string, i: number) => (
                                <SwiperSlide key={i} className="flex justify-center">
                                    <img onLoad={onImageLoadB} className={`h-48 sm:h-[71px] transition-all duration-300 aspect-video  
                            ${activeIndex === i ? '' : 'scale-[0.8]'}`} src={item} alt=""/>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        <Arrow onClick={() => swiper.slideNext()} color='#3869F1'
                               className='w-2 h-4 absolute top-1/2 -translate-y-1/2 cursor-pointer z-50 right-4 rotate-180 lg:hidden'/>
                        <Arrow onClick={() => swiper.slidePrev()} color='#3869F1'
                               className='w-2 h-4 absolute top-1/2 -translate-y-1/2 cursor-pointer z-50 left-4 lg:hidden'/>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export default MobileSlider
