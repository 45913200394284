import React from 'react'
import PressCard from '../PressCard/PressCard'
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const PressOpinion = () => {
    const {t} = useTranslation();


    return (
        <section id='blog'
                 className='flex flex-col gap-y-12 md:gap-y-4 max-w-7xl mx-auto mt-10 sm:mt-10 md:mt-6 lg:px-4 minmd:px-4'>
            <div
                className='flex flex-row items-center justify-between md:flex-col md:items-start md:space-y-2 md:px-4'>
                <h3 className='text-4xl sm:text-2xl sm:text-left md:text-3xl  font-semibold text-[#1B2A3E]'>
                    {t<string>("eighthTitle")}
                </h3>

                <Link to='/article'>
                    <p className='text-md underline  decoration-[#475569] text-blue-600 flex-end underline-offset-4'>
                        {t<string>("seeall")}
                    </p>
                </Link>
            </div>
            <div className='md:flex sm:px-2'>
                <PressCard/>
            </div>
        </section>


    )
}

export default PressOpinion
