import React from 'react'

const Video = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.77832 6.79148C0.77832 3.42102 3.51062 0.688721 6.88108 0.688721H20.3071C23.6776 0.688721 26.4099 3.42102 26.4099 6.79148V20.2175C26.4099 23.588 23.6776 26.3203 20.3071 26.3203H6.88108C3.51062 26.3203 0.77832 23.588 0.77832 20.2175V6.79148ZM6.88108 1.90927C4.18471 1.90927 1.99887 4.09511 1.99887 6.79148V20.2175C1.99887 22.9139 4.18471 25.0997 6.88108 25.0997H20.3071C23.0035 25.0997 25.1893 22.9139 25.1893 20.2175V6.79148C25.1893 4.09511 23.0035 1.90927 20.3071 1.90927H6.88108Z" fill="#333333"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.2606 11.7438C10.4624 11.6387 10.706 11.6547 10.8924 11.7851L16.9951 16.0571C17.1583 16.1712 17.2554 16.3578 17.2554 16.557C17.2554 16.7562 17.1583 16.9427 16.9951 17.0569L10.8924 21.3289C10.706 21.4594 10.4624 21.4753 10.2606 21.3703C10.0587 21.2652 9.93213 21.0564 9.93213 20.8289V12.2851C9.93213 12.0576 10.0587 11.8489 10.2606 11.7438ZM11.1527 13.4572V19.6568L15.581 16.557L11.1527 13.4572Z" fill="#333333"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.77832 8.62297C0.77832 8.28593 1.05155 8.0127 1.3886 8.0127H25.7996C26.1366 8.0127 26.4099 8.28593 26.4099 8.62297C26.4099 8.96001 26.1366 9.23325 25.7996 9.23325H1.3886C1.05155 9.23325 0.77832 8.96001 0.77832 8.62297Z" fill="#333333"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.51853 0.720207C8.83828 0.613617 9.18389 0.786422 9.29048 1.10617L11.7316 8.42948C11.8382 8.74924 11.6653 9.09485 11.3455 9.20143C11.0259 9.30801 10.6802 9.1352 10.5737 8.81545L8.13255 1.49214C8.02598 1.1724 8.19878 0.826786 8.51853 0.720207Z" fill="#333333"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.8425 0.720207C16.1622 0.613617 16.5079 0.786422 16.6145 1.10617L19.0556 8.42948C19.1621 8.74924 18.9893 9.09485 18.6695 9.20143C18.3498 9.30801 18.0042 9.1352 17.8976 8.81545L15.4565 1.49214C15.35 1.1724 15.5228 0.826786 15.8425 0.720207Z" fill="#333333"/>
    </svg>
    
  )
}

export default Video