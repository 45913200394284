import Bag from '../../../../assets/icons/Bag'
import User from '../../../../assets/icons/User'
import Notes from '../../../../assets/icons/Notes'
import {useTranslation} from 'react-i18next'


const JobsCard = (props: any) => {
    const {t} = useTranslation()

    return (
        <>

            <div className=" flex flex-row minlg:space-x-10 lg:space-x-5 items-center px-4  text-left pb-40 pt-10 ">
                {props.oportunityList.map((oportunityItem: any, i: number) => {
                    return (
                        <div
                            key={i}
                            className=" shadow-md shadow-slate-100  border border-[#EBEAEA] border-opacity-40 pt-8 pb-6 outline-1 rounded-[20px] minxl:w-[300px] minlg:w-[290px] w-[250px] drop-shadow-xl even:translate-y-20    "
                        >
                            <div className="px-6">
                                <div className="min-h-[120px] space-y-2">
                                    <h5 className="text-[#010101] font-semibold">
                                        {oportunityItem.title}
                                    </h5>
                                    <div className="flex space-x-2">
                  <span className="flex items-center">
                    <span
                        className="animate-ping absolute inline-flex h-2 w-2 rounded-full bg-[#1e3a8a] opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-2 w-2 bg-[#1e3a8a]"></span>
                  </span>
                                        <p className="font-medium text-sm  leading-8 text-[#1e3a8a]  opacity-75">
                                            {oportunityItem.subtitle}
                                        </p></div>
                                    <p className="font-medium text-sm tracking-wide  leading-relaxed text-[#54627B]  ">
                                        {oportunityItem.country}
                                    </p>
                                </div>
                            </div>
                            <hr className="mt-5  mb-6 mx-4"/>
                            <div className="flex flex-row items-center justify-between px-8">
                                <div className="flex flex-col items-center text-sm gap-y-1">
                                    <Notes/>
                                    <div className="flex space-x-1">
                                        <p className="text-[#6b7280] ">
                                            {oportunityItem.positions}
                                        </p>
                                        <p className="text-[#6b7280]">{t<string>("item1")}</p>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center text-sm gap-y-1">
                                    <User/>
                                    <div className="flex space-x-1">
                                        <p className=" text-[#6b7280]">
                                            {oportunityItem.sourced}
                                        </p>
                                        <p className="text-[#6b7280]">{t<string>("item2")}</p>
                                    </div>
                                </div>

                                <div className="flex flex-col items-center text-sm gap-y-1">
                                    <Bag/>
                                    <div className="flex space-x-1">
                                        <p className="text-[#6b7280]">
                                            {oportunityItem.hired}
                                        </p>
                                        <p className="text-[#6b7280]">{t<string>("item3")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })

                }

            </div>
        </>

    );
};

export default JobsCard;
