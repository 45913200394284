import React from "react";
import {useTranslation} from "react-i18next";
import FuturePic from "../../../../assets/img/FuturePic";
import {useLocation} from 'react-router-dom'
import futuremobile from "../../../../assets/pictures/futuremobile.png"
import {AppStoreButton, GooglePlayButton,} from "../../../../assets/img/StoresButton";

const FutureView = () => {
    const {t} = useTranslation();
    const location = useLocation()

    return (
        <div id="future" className="bg-[#eef2ff]">
            <div className=" max-w-7xl mx-auto md:pb-8 sm:pb-1 sm:mr-4 minmd:h-fit   minlg:h-fit  md:h-fit  ">
                <div className="flex flex-row sm:flex-col  mb-10   minlg:mt-40 minmd:pb-2 pt-10">
                    <div
                        className="flex flex-col items-start   minlg:w-[60%]  lg:w-[100%]  lg:pl-6 sm:pl-4  minmd:pl-10 space-y-6 md:space-y-6 ">
                        <div className='flex flex-col gap-y-12'>
                            <div className='flex flex-col gap-y-2'>
                                <h1 className="text-4xl  lg:text-2xl md:text-2xl md:mb-6 font-semibold text-[#1B2A3E]">
                                    {t<string>("searchtitle")}
                                </h1>
                                <p className="text-2xl lg:text-xl text-[#333333] md:text-base sm:text-sm ">
                                    {t<string>("searchsubtitle")}
                                </p>
                            </div>
                            <div className='flex flex-col gap-y-4 w-[70%] sm:w-full lg:w-[70%]  md:w-full'>
                                <p
                                    className="text-sm md:text-xs  md:pr-10 sm:pr-0 md:leading-[22px]  text-[#6b7280] leading-6 text-justify">
                                    {t<string>("searchingtext")}
                                </p>
                                <p
                                    className="text-sm lg:text-xs md:leading-[22px]  text-[#333333] leading-8 text-justify font-bold">
                                    {t<string>("searchend")}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-row gap-x-4  ">
                            <GooglePlayButton href="https://play.google.com/store/apps/details?id=at.redev.android"/>
                            <AppStoreButton href="https://apps.apple.com/at/app/redev/id1633895339?l=en"/>
                        </div>
                    </div>

                    <div
                        className="flex justify-end sm:justify-center sm:items-center sm:w-full sm:mr-0 lg:mr-10 lg:items-center minlg:pr-20 ">
                        <img alt="" src={futuremobile} className="minsm:hidden mt-10"/>
                        <FuturePic
                            className=" minlg:scale-125 md:w-72  minlg:w-96 sm:w-64 sm:translate-y-10 sm:mt-6 sm:pb-10 sm:hidden "/>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FutureView;
