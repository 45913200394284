import React from 'react'

const Bag = ({ color, className }: { color?: string, className?: string})  => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_5_1699)">
<path d="M21.3327 18.9329C21.3327 19.9878 21.0199 21.0189 20.4339 21.896C19.8478 22.773 19.0149 23.4566 18.0403 23.8603C17.0658 24.264 15.9934 24.3696 14.9589 24.1638C13.9243 23.958 12.974 23.4501 12.2281 22.7042C11.4822 21.9583 10.9743 21.008 10.7685 19.9734C10.5627 18.9389 10.6683 17.8665 11.072 16.892C11.4757 15.9174 12.1592 15.0845 13.0363 14.4984C13.9134 13.9124 14.9445 13.5996 15.9994 13.5996C17.4138 13.5996 18.7704 14.1615 19.7706 15.1617C20.7708 16.1619 21.3327 17.5185 21.3327 18.9329Z" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.9199 19.0664L14.7866 19.9331C14.9089 20.0531 15.0726 20.1214 15.2439 20.1239C15.4152 20.1264 15.5809 20.0628 15.7066 19.9464L18.0799 17.7598" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.6658 29.3333H21.3325C26.6925 29.3333 27.6525 27.1867 27.9325 24.5733L28.9325 13.9067C29.2925 10.6533 28.3591 8 22.6658 8H9.33245C3.63912 8 2.70578 10.6533 3.06578 13.9067L4.06578 24.5733C4.34578 27.1867 5.30578 29.3333 10.6658 29.3333Z" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.666 7.99935V6.93268C10.666 4.57268 10.666 2.66602 14.9327 2.66602H17.066C21.3327 2.66602 21.3327 4.57268 21.3327 6.93268V7.99935" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M28.8657 14.666C26.6079 16.3107 24.0549 17.5058 21.3457 18.186" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.49414 15.0273C5.67204 16.522 8.10224 17.6107 10.6675 18.2407" stroke="#54627B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</g>
<defs>
<clipPath id="clip0_5_1699">
<rect width="32" height="32" fill="white"/>
</clipPath>
</defs>
</svg>
  )
}

export default Bag