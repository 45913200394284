const TweeterIco = ({className}: { className?: string }) => {
    return (
        <svg className={className} width="60" height="48" viewBox="0 0 60 48" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M60 5.68197C57.7943 6.64715 55.4205 7.2982 52.9298 7.58955C55.4729 6.09082 57.4228 3.71684 58.3424 0.884848C55.9637 2.27447 53.3305 3.28281 50.5255 3.82715C48.2809 1.47235 45.0813 0 41.5407 0C34.7433 0 29.2321 5.42539 29.2321 12.1181C29.2321 13.0665 29.3405 13.9909 29.5512 14.8794C19.3204 14.3734 10.2491 9.54988 4.17758 2.21811C3.11796 4.00699 2.51142 6.08842 2.51142 8.31133C2.51142 12.515 4.68425 16.2246 7.98733 18.3972C5.9704 18.3336 4.07162 17.7881 2.41155 16.8805C2.41033 16.9308 2.41033 16.9824 2.41033 17.0339C2.41033 22.9041 6.65367 27.8008 12.2855 28.9158C11.2527 29.1916 10.165 29.3403 9.04208 29.3403C8.24798 29.3403 7.47701 29.2647 6.72553 29.122C8.29304 33.936 12.8384 37.4406 18.2242 37.5377C14.0113 40.7881 8.70471 42.7257 2.93648 42.7257C1.94385 42.7257 0.963401 42.6681 0 42.5554C5.44912 45.9953 11.9189 48 18.8697 48C41.5127 48 53.8932 29.5357 53.8932 13.5221C53.8932 12.997 53.8822 12.473 53.8591 11.9526C56.2633 10.2477 58.3509 8.1123 60 5.68197Z"
                fill="black"/>
        </svg>

    )
}

export default TweeterIco