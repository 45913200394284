const AppleStoreIcon = () => {
    return (
        <svg width="150" height="40" viewBox="0 0 179 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M171.943 52H6.24528C2.80239 52 0 49.2336 0 45.8471V6.1438C0 2.756 2.80239 0 6.24528 0H171.943C175.384 0 178.286 2.756 178.286 6.1438V45.8471C178.286 49.2336 175.384 52 171.943 52Z"
                fill="black"/>
            <path
                d="M177.008 45.8516C177.008 48.6024 174.744 50.8306 171.944 50.8306H6.2457C3.44727 50.8306 1.17578 48.6024 1.17578 45.8516V6.14566C1.17578 3.39616 3.44727 1.16016 6.2457 1.16016H171.943C174.744 1.16016 177.006 3.39616 177.006 6.14566L177.008 45.8516Z"
                fill="black"/>
            <path
                d="M39.7892 25.7191C39.7509 21.5292 43.2743 19.4908 43.4355 19.3959C41.44 16.532 38.3471 16.1407 37.2602 16.1095C34.6625 15.8404 32.1427 17.6396 30.8194 17.6396C29.4697 17.6396 27.432 16.1355 25.2358 16.1797C22.4096 16.2226 19.7657 17.8333 18.3157 20.3345C15.3231 25.4344 17.555 32.9289 20.4221 37.0512C21.8563 39.0701 23.5322 41.3243 25.7257 41.245C27.8718 41.1579 28.6734 39.8982 31.2632 39.8982C33.8292 39.8982 34.5819 41.245 36.8191 41.1943C39.1223 41.1579 40.5723 39.1663 41.9564 37.1292C43.6137 34.8152 44.2794 32.5363 44.3058 32.4193C44.2516 32.4011 39.8328 30.741 39.7892 25.7191Z"
                fill="white"/>
            <path
                d="M35.5626 13.3977C36.7168 11.9768 37.5065 10.0437 37.2873 8.08203C35.6167 8.15483 33.5275 9.21953 32.3244 10.6092C31.2599 11.8338 30.3091 13.841 30.5547 15.7286C32.4313 15.8664 34.3581 14.7965 35.5626 13.3977Z"
                fill="white"/>
            <path
                d="M70.8451 40.9567H67.846L66.2031 35.875H60.4927L58.9277 40.9567H56.0078L61.6654 23.6562H65.1598L70.8451 40.9567ZM65.7079 33.743L64.2222 29.2255C64.065 28.764 63.7705 27.6772 63.336 25.9664H63.2832C63.1102 26.7022 62.8315 27.789 62.4485 29.2255L60.9892 33.743H65.7079Z"
                fill="white"/>
            <path
                d="M85.3934 34.5667C85.3934 36.6883 84.811 38.3653 83.6462 39.5964C82.6029 40.6923 81.3074 41.2396 79.7609 41.2396C78.0916 41.2396 76.8925 40.6494 76.1622 39.469H76.1094V46.0405H73.2938V32.5894C73.2938 31.2556 73.2581 29.8867 73.1895 28.4827H75.6656L75.8228 30.46H75.8756C76.8146 28.9702 78.2396 28.2266 80.1518 28.2266C81.6468 28.2266 82.8948 28.8077 83.8932 29.9712C84.8942 31.136 85.3934 32.6674 85.3934 34.5667ZM82.525 34.6681C82.525 33.4539 82.2477 32.4529 81.6904 31.6651C81.0816 30.8435 80.2641 30.4327 79.2393 30.4327C78.5446 30.4327 77.9134 30.6615 77.3495 31.1126C76.7842 31.5676 76.4144 32.1617 76.2414 32.8975C76.1543 33.2407 76.1107 33.5215 76.1107 33.7425V35.8225C76.1107 36.7299 76.3933 37.4956 76.9585 38.1209C77.5238 38.7462 78.2581 39.0582 79.1614 39.0582C80.2218 39.0582 81.0472 38.6552 81.6376 37.8518C82.2292 37.0471 82.525 35.9863 82.525 34.6681Z"
                fill="white"/>
            <path
                d="M99.9683 34.5667C99.9683 36.6883 99.3859 38.3653 98.2198 39.5964C97.1778 40.6923 95.8822 41.2396 94.3358 41.2396C92.6665 41.2396 91.4674 40.6494 90.7384 39.469H90.6855V46.0405H87.87V32.5894C87.87 31.2556 87.8343 29.8867 87.7656 28.4827H90.2418L90.399 30.46H90.4518C91.3894 28.9702 92.8144 28.2266 94.728 28.2266C96.2217 28.2266 97.4697 28.8077 98.4707 29.9712C99.4678 31.136 99.9683 32.6674 99.9683 34.5667ZM97.0999 34.6681C97.0999 33.4539 96.8212 32.4529 96.2639 31.6651C95.6551 30.8435 94.8403 30.4327 93.8141 30.4327C93.1182 30.4327 92.4882 30.6615 91.923 31.1126C91.3578 31.5676 90.9893 32.1617 90.8163 32.8975C90.7304 33.2407 90.6855 33.5215 90.6855 33.7425V35.8225C90.6855 36.7299 90.9682 37.4956 91.5308 38.1209C92.096 38.7449 92.8303 39.0582 93.7362 39.0582C94.7967 39.0582 95.6221 38.6552 96.2124 37.8518C96.8041 37.0471 97.0999 35.9863 97.0999 34.6681Z"
                fill="white"/>
            <path
                d="M116.265 36.1046C116.265 37.5762 115.745 38.7735 114.704 39.6978C113.559 40.7079 111.965 41.2123 109.916 41.2123C108.025 41.2123 106.509 40.8535 105.361 40.1346L106.014 37.8245C107.25 38.5603 108.606 38.9295 110.084 38.9295C111.144 38.9295 111.97 38.6929 112.563 38.2223C113.153 37.7517 113.448 37.1199 113.448 36.3321C113.448 35.6301 113.205 35.0386 112.717 34.5589C112.233 34.0792 111.423 33.6333 110.293 33.2212C107.216 32.0915 105.678 30.4366 105.678 28.2604C105.678 26.8382 106.217 25.6721 107.296 24.7647C108.371 23.856 109.805 23.4023 111.599 23.4023C113.198 23.4023 114.527 23.6766 115.587 24.2239L114.883 26.4833C113.893 25.9529 112.773 25.6877 111.519 25.6877C110.529 25.6877 109.755 25.9282 109.2 26.4066C108.732 26.8343 108.497 27.3556 108.497 27.9731C108.497 28.6569 108.765 29.2224 109.303 29.667C109.772 30.0778 110.624 30.5224 111.86 31.0021C113.372 31.6014 114.483 32.3021 115.197 33.1055C115.909 33.9063 116.265 34.9086 116.265 36.1046Z"
                fill="white"/>
            <path
                d="M125.577 30.5594H122.474V36.6161C122.474 38.1566 123.02 38.9262 124.117 38.9262C124.62 38.9262 125.037 38.8833 125.367 38.7975L125.445 40.9022C124.89 41.1063 124.16 41.209 123.255 41.209C122.144 41.209 121.275 40.8749 120.647 40.208C120.023 39.5398 119.708 38.4192 119.708 36.8449V30.5568H117.859V28.4768H119.708V26.1927L122.474 25.3711V28.4768H125.577V30.5594Z"
                fill="white"/>
            <path
                d="M139.579 34.6158C139.579 36.5333 139.021 38.1076 137.909 39.3387C136.743 40.6062 135.195 41.238 133.266 41.238C131.407 41.238 129.926 40.6309 128.822 39.4167C127.718 38.2025 127.166 36.6698 127.166 34.8225C127.166 32.8894 127.734 31.306 128.874 30.0749C130.011 28.8425 131.545 28.2263 133.475 28.2263C135.334 28.2263 136.83 28.8334 137.96 30.0489C139.04 31.228 139.579 32.7503 139.579 34.6158ZM136.657 34.7055C136.657 33.555 136.408 32.5683 135.902 31.7454C135.312 30.7496 134.468 30.253 133.374 30.253C132.243 30.253 131.383 30.7509 130.792 31.7454C130.287 32.5696 130.037 33.5719 130.037 34.7575C130.037 35.908 130.287 36.8947 130.792 37.7163C131.401 38.7121 132.252 39.2087 133.349 39.2087C134.424 39.2087 135.268 38.7017 135.877 37.6903C136.396 36.8518 136.657 35.8547 136.657 34.7055Z"
                fill="white"/>
            <path
                d="M148.729 30.9202C148.451 30.8695 148.154 30.8435 147.842 30.8435C146.851 30.8435 146.085 31.2114 145.547 31.9485C145.078 32.5985 144.843 33.4201 144.843 34.412V40.9575H142.028L142.055 32.4113C142.055 30.9735 142.019 29.6644 141.949 28.484H144.402L144.505 30.8708H144.583C144.88 30.0505 145.349 29.3901 145.99 28.8948C146.618 28.4489 147.295 28.2266 148.025 28.2266C148.286 28.2266 148.521 28.2448 148.729 28.2773V30.9202Z"
                fill="white"/>
            <path
                d="M161.321 34.1298C161.321 34.6264 161.288 35.045 161.218 35.3869H152.771C152.804 36.6193 153.212 37.5618 153.997 38.2118C154.709 38.7929 155.629 39.0841 156.76 39.0841C158.01 39.0841 159.151 38.8878 160.177 38.4939L160.618 40.4179C159.419 40.9327 158.004 41.1888 156.37 41.1888C154.405 41.1888 152.862 40.6194 151.74 39.4819C150.62 38.3444 150.059 36.8169 150.059 34.9007C150.059 33.0196 150.58 31.4531 151.625 30.2038C152.718 28.87 154.196 28.2031 156.056 28.2031C157.882 28.2031 159.265 28.87 160.204 30.2038C160.947 31.2633 161.321 32.5737 161.321 34.1298ZM158.636 33.4109C158.655 32.5893 158.471 31.8795 158.089 31.2802C157.602 30.5093 156.853 30.1245 155.846 30.1245C154.925 30.1245 154.176 30.5002 153.605 31.2542C153.136 31.8535 152.857 32.5724 152.771 33.4096H158.636V33.4109Z"
                fill="white"/>
            <path
                d="M64.7768 13.0142C64.7768 14.5443 64.3106 15.6961 63.3796 16.4696C62.5172 17.1833 61.2916 17.5408 59.7042 17.5408C58.9171 17.5408 58.2436 17.507 57.6797 17.4394V9.07914C58.4153 8.96214 59.2077 8.90234 60.0634 8.90234C61.5756 8.90234 62.7153 9.22604 63.4839 9.87344C64.3449 10.6053 64.7768 11.6518 64.7768 13.0142ZM63.3175 13.0519C63.3175 12.06 63.0507 11.2995 62.5172 10.7691C61.9836 10.24 61.2045 9.97484 60.1783 9.97484C59.7425 9.97484 59.3714 10.0034 59.0637 10.0632V16.4189C59.2341 16.4449 59.5457 16.4566 59.9987 16.4566C61.0579 16.4566 61.8753 16.1667 62.4511 15.5869C63.0269 15.0071 63.3175 14.1621 63.3175 13.0519Z"
                fill="white"/>
            <path
                d="M72.5153 14.3452C72.5153 15.2877 72.242 16.0599 71.6952 16.6657C71.1221 17.2884 70.3627 17.5991 69.4145 17.5991C68.5006 17.5991 67.7729 17.3014 67.2302 16.7034C66.6887 16.1067 66.418 15.354 66.418 14.4466C66.418 13.4976 66.6966 12.7189 67.2566 12.1144C67.8165 11.5099 68.5693 11.207 69.5175 11.207C70.4314 11.207 71.1656 11.5047 71.7216 12.1014C72.2499 12.6812 72.5153 13.43 72.5153 14.3452ZM71.0798 14.3894C71.0798 13.8239 70.9557 13.339 70.7087 12.9347C70.4182 12.4459 70.0048 12.2015 69.4673 12.2015C68.9113 12.2015 68.4887 12.4459 68.1982 12.9347C67.9499 13.339 67.8271 13.8317 67.8271 14.4141C67.8271 14.9796 67.9512 15.4645 68.1982 15.8688C68.498 16.3576 68.9153 16.602 69.4541 16.602C69.9824 16.602 70.397 16.3537 70.6955 15.8558C70.9517 15.4437 71.0798 14.9549 71.0798 14.3894Z"
                fill="white"/>
            <path
                d="M82.891 11.332L80.9431 17.4602H79.6753L78.8684 14.7991C78.6637 14.1348 78.4973 13.4744 78.3679 12.8192H78.3428C78.2226 13.4926 78.0562 14.1517 77.8422 14.7991L76.9851 17.4602H75.7028L73.8711 11.332H75.2934L75.9973 14.2453C76.1677 14.9343 76.3077 15.5908 76.4199 16.2122H76.445C76.548 15.7 76.7184 15.0474 76.9587 14.2583L77.8422 11.3333H78.9701L79.8166 14.1959C80.0213 14.894 80.1877 15.5661 80.3158 16.2135H80.3541C80.4479 15.583 80.5892 14.9109 80.7767 14.1959L81.5321 11.3333H82.891V11.332Z"
                fill="white"/>
            <path
                d="M90.064 17.4603H88.68V13.9503C88.68 12.8687 88.2627 12.3279 87.4254 12.3279C87.0147 12.3279 86.6832 12.4761 86.4257 12.7738C86.1708 13.0715 86.0414 13.4225 86.0414 13.8242V17.459H84.6574V13.0832C84.6574 12.545 84.6402 11.9613 84.6072 11.3295H85.8235L85.8882 12.2876H85.9265C86.0876 11.9899 86.328 11.7442 86.6436 11.5479C87.0187 11.3191 87.4386 11.2034 87.8982 11.2034C88.4793 11.2034 88.9626 11.388 89.3469 11.7585C89.825 12.2122 90.064 12.8895 90.064 13.7891V17.4603Z"
                fill="white"/>
            <path d="M93.8827 17.4635H92.5V8.52344H93.8827V17.4635Z" fill="white"/>
            <path
                d="M102.031 14.3452C102.031 15.2877 101.758 16.0599 101.211 16.6657C100.638 17.2884 99.877 17.5991 98.9301 17.5991C98.0149 17.5991 97.2872 17.3014 96.7458 16.7034C96.2043 16.1067 95.9336 15.354 95.9336 14.4466C95.9336 13.4976 96.2123 12.7189 96.7722 12.1144C97.3321 11.5099 98.0849 11.207 99.0318 11.207C99.947 11.207 100.68 11.5047 101.237 12.1014C101.766 12.6812 102.031 13.43 102.031 14.3452ZM100.594 14.3894C100.594 13.8239 100.47 13.339 100.223 12.9347C99.9338 12.4459 99.5191 12.2015 98.9829 12.2015C98.4256 12.2015 98.003 12.4459 97.7138 12.9347C97.4655 13.339 97.3427 13.8317 97.3427 14.4141C97.3427 14.9796 97.4668 15.4645 97.7138 15.8688C98.0136 16.3576 98.4309 16.602 98.9697 16.602C99.498 16.602 99.9113 16.3537 100.21 15.8558C100.467 15.4437 100.594 14.9549 100.594 14.3894Z"
                fill="white"/>
            <path
                d="M108.728 17.46H107.485L107.382 16.7541H107.344C106.919 17.317 106.313 17.5991 105.526 17.5991C104.938 17.5991 104.462 17.4132 104.105 17.044C103.78 16.7086 103.617 16.2913 103.617 15.796C103.617 15.0472 103.934 14.4765 104.572 14.0813C105.209 13.6861 106.104 13.4924 107.257 13.5015V13.3871C107.257 12.5798 106.826 12.1768 105.964 12.1768C105.35 12.1768 104.808 12.3289 104.341 12.6305L104.06 11.7361C104.638 11.3838 105.353 11.207 106.195 11.207C107.822 11.207 108.638 12.052 108.638 13.742V15.9988C108.638 16.6111 108.669 17.0986 108.728 17.46ZM107.291 15.354V14.4089C105.765 14.3829 105.001 14.795 105.001 15.6439C105.001 15.9637 105.088 16.2029 105.267 16.3628C105.445 16.5227 105.672 16.602 105.943 16.602C106.247 16.602 106.531 16.5071 106.789 16.3186C107.05 16.1288 107.209 15.8883 107.269 15.5932C107.283 15.5269 107.291 15.4463 107.291 15.354Z"
                fill="white"/>
            <path
                d="M116.593 17.4633H115.365L115.301 16.4792H115.262C114.87 17.228 114.202 17.6024 113.263 17.6024C112.513 17.6024 111.888 17.3125 111.393 16.7327C110.898 16.1529 110.651 15.4002 110.651 14.4759C110.651 13.484 110.919 12.6806 111.458 12.067C111.979 11.495 112.618 11.209 113.379 11.209C114.215 11.209 114.8 11.4859 115.133 12.041H115.159V8.52319H116.545V15.8123C116.545 16.409 116.56 16.9589 116.593 17.4633ZM115.159 14.8789V13.8571C115.159 13.6803 115.146 13.5373 115.121 13.4281C115.043 13.1005 114.875 12.8249 114.62 12.6026C114.363 12.3803 114.053 12.2685 113.695 12.2685C113.178 12.2685 112.774 12.47 112.477 12.8743C112.183 13.2786 112.033 13.7947 112.033 14.4252C112.033 15.031 112.175 15.5224 112.459 15.9007C112.758 16.3037 113.162 16.5052 113.668 16.5052C114.123 16.5052 114.486 16.3375 114.762 16.0008C115.029 15.6901 115.159 15.3157 115.159 14.8789Z"
                fill="white"/>
            <path
                d="M128.429 14.3452C128.429 15.2877 128.156 16.0599 127.609 16.6657C127.036 17.2884 126.278 17.5991 125.329 17.5991C124.416 17.5991 123.688 17.3014 123.144 16.7034C122.603 16.1067 122.332 15.354 122.332 14.4466C122.332 13.4976 122.611 12.7189 123.171 12.1144C123.731 11.5099 124.483 11.207 125.433 11.207C126.345 11.207 127.081 11.5047 127.636 12.1014C128.164 12.6812 128.429 13.43 128.429 14.3452ZM126.995 14.3894C126.995 13.8239 126.871 13.339 126.624 12.9347C126.332 12.4459 125.92 12.2015 125.381 12.2015C124.827 12.2015 124.404 12.4459 124.112 12.9347C123.864 13.339 123.741 13.8317 123.741 14.4141C123.741 14.9796 123.865 15.4645 124.112 15.8688C124.412 16.3576 124.829 16.602 125.368 16.602C125.896 16.602 126.312 16.3537 126.611 15.8558C126.866 15.4437 126.995 14.9549 126.995 14.3894Z"
                fill="white"/>
            <path
                d="M135.874 17.46H134.491V13.95C134.491 12.8684 134.073 12.3276 133.235 12.3276C132.824 12.3276 132.493 12.4758 132.236 12.7735C131.98 13.0712 131.852 13.4222 131.852 13.8239V17.4587H130.467V13.0829C130.467 12.5447 130.451 11.961 130.418 11.3292H131.633L131.698 12.2873H131.736C131.898 11.9896 132.139 11.7439 132.453 11.5476C132.829 11.3188 133.248 11.2031 133.709 11.2031C134.289 11.2031 134.772 11.3877 135.156 11.7582C135.636 12.2119 135.874 12.8892 135.874 13.7888V17.46Z"
                fill="white"/>
            <path
                d="M145.186 12.3514H143.662V15.3284C143.662 16.085 143.933 16.4633 144.469 16.4633C144.717 16.4633 144.923 16.4425 145.086 16.3996L145.121 17.4331C144.848 17.5345 144.489 17.5852 144.046 17.5852C143.5 17.5852 143.074 17.4214 142.767 17.0938C142.458 16.7662 142.304 16.215 142.304 15.4415V12.3514H141.395V11.3309H142.304V10.2077L143.661 9.80469V11.3296H145.185V12.3514H145.186Z"
                fill="white"/>
            <path
                d="M152.512 17.4633H151.127V13.9793C151.127 12.8808 150.709 12.3309 149.873 12.3309C149.231 12.3309 148.793 12.6494 148.553 13.2864C148.512 13.4203 148.488 13.5841 148.488 13.7765V17.462H147.105V8.52319H148.488V12.2165H148.514C148.95 11.5444 149.575 11.209 150.384 11.209C150.958 11.209 151.432 11.3936 151.808 11.7641C152.277 12.2256 152.512 12.912 152.512 13.8194V17.4633Z"
                fill="white"/>
            <path
                d="M160.072 14.1099C160.072 14.3543 160.053 14.5597 160.02 14.7274H155.87C155.888 15.3332 156.086 15.7947 156.471 16.1145C156.822 16.4005 157.275 16.5435 157.829 16.5435C158.444 16.5435 159.003 16.4473 159.508 16.2536L159.725 17.2C159.134 17.4522 158.44 17.5783 157.635 17.5783C156.671 17.5783 155.912 17.2988 155.363 16.7398C154.81 16.1808 154.537 15.4307 154.537 14.4895C154.537 13.5652 154.792 12.7956 155.306 12.182C155.842 11.5268 156.567 11.1992 157.482 11.1992C158.378 11.1992 159.058 11.5268 159.517 12.182C159.888 12.702 160.072 13.3455 160.072 14.1099ZM158.751 13.7576C158.762 13.3533 158.671 13.0049 158.483 12.7111C158.243 12.3328 157.877 12.143 157.382 12.143C156.93 12.143 156.562 12.3276 156.28 12.6981C156.051 12.9932 155.915 13.3455 155.87 13.7576H158.751Z"
                fill="white"/>
        </svg>

    )
}

export default AppleStoreIcon