const InstaIco = ({className}: { className?: string }) => {
    return (
        <svg className={className} width="49" height="48" viewBox="0 0 49 48" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M24.6678 31.6842C28.9093 31.6842 32.3478 28.2458 32.3478 24.0042C32.3478 19.7627 28.9093 16.3242 24.6678 16.3242C20.4262 16.3242 16.9878 19.7627 16.9878 24.0042C16.9878 28.2458 20.4262 31.6842 24.6678 31.6842Z"
                fill="black"/>
            <path
                d="M35.2267 0H14.1067C10.5422 0 7.12372 1.416 4.60323 3.93648C2.08274 6.45697 0.666748 9.87549 0.666748 13.44V34.56C0.666748 38.1245 2.08274 41.543 4.60323 44.0635C7.12372 46.584 10.5422 48 14.1067 48H35.2267C38.7913 48 42.2098 46.584 44.7303 44.0635C47.2508 41.543 48.6667 38.1245 48.6667 34.56V13.44C48.6667 9.87549 47.2508 6.45697 44.7303 3.93648C42.2098 1.416 38.7913 0 35.2267 0ZM24.6667 35.52C22.3883 35.52 20.161 34.8444 18.2666 33.5785C16.3721 32.3127 14.8956 30.5135 14.0237 28.4085C13.1517 26.3035 12.9236 23.9872 13.3681 21.7526C13.8126 19.5179 14.9098 17.4652 16.5209 15.8541C18.132 14.243 20.1846 13.1459 22.4193 12.7014C24.654 12.2569 26.9703 12.485 29.0753 13.3569C31.1803 14.2288 32.9794 15.7054 34.2453 17.5998C35.5111 19.4943 36.1867 21.7216 36.1867 24C36.1867 27.0553 34.973 29.9855 32.8126 32.1459C30.6522 34.3063 27.722 35.52 24.6667 35.52ZM37.1467 14.4C36.5771 14.4 36.0203 14.2311 35.5467 13.9146C35.0731 13.5982 34.704 13.1484 34.486 12.6221C34.268 12.0959 34.211 11.5168 34.3221 10.9581C34.4332 10.3995 34.7075 9.88631 35.1103 9.48353C35.5131 9.08076 36.0262 8.80646 36.5849 8.69534C37.1436 8.58421 37.7226 8.64125 38.2489 8.85923C38.7751 9.07721 39.2249 9.44634 39.5414 9.91996C39.8578 10.3936 40.0267 10.9504 40.0267 11.52C40.0267 12.2838 39.7233 13.0164 39.1832 13.5565C38.6431 14.0966 37.9106 14.4 37.1467 14.4Z"
                fill="black"/>
        </svg>

    )
}

export default InstaIco