import React, {useEffect} from "react";
import toleft from "../../../../assets/toleft.png";
import leftb from "../../../../assets/leftb.png";
import {useTranslation} from "react-i18next";

import leftl from "../../../../assets/leftl.png";

import avatar from "../../../../assets/avatar.png";
import seconavatar from "../../../../assets/seconavatar.png";
import thirdavatar from "../../../../assets/thirdavatar.png";
import Vector from "../../../../assets/img/Vector";
import rotated from "../../../../assets/rotated.png";
import Star from "../../../../assets/icons/Star";
import ForJob from "../../../../assets/icons/ForJob";
import Cardvect from "../../../../assets/Cardvect.png";
import vectorr from "../../../../assets/vectorr.png";
import {motion, useAnimation} from "framer-motion";

import {useInView} from "react-intersection-observer";

const Cards = () => {
    // const ref = useRef(null);
    // const inView = useInView(ref);
    const {t} = useTranslation();

    const controls = useAnimation();
    const [ref, inView] = useInView();
    const container = {
        hidden: {pathLength: 0, opacity: 0},
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                delayChildren: 0.3,
                staggerChildren: 0.2,
            },
        },
    };


    const item = {
        hidden: {y: 20, opacity: 0},
        visible: {
            y: 0,
            opacity: 1,
        },
    };
    useEffect(() => {
        if (inView) {
            controls.start("visible");
        } else if (!inView) {
            controls.start("hidden");
        }
    }, [controls, inView]);
    return (
        <motion.div className="sm:w-full sm:flex sm:items-center sm:justify-end ">
            <motion.div
                ref={ref}
                animate={controls}
                variants={container}
                initial="hidden"
                className="sm:mx-0 sm:px-4 rounded-3xl relative h-[26rem] lg:mt-20 md:mt-10  bg-[#dbeafe] mb-10 mr-20 xl:mr-4  space-y-4 minmd:-top-11  md:right-0 sm:max-h-[18rem] sm:w-2/3"
            >
                <motion.div variants={item} className="flex space-x-2  pt-4 ml-4">
                    <p className="text-[#1c1917] md:text-sm sm:text-xs font-semibold">
                        {t<string>("card")}
                    </p>
                    <img className="scale-75 md:scale-50 " alt="" src={toleft}/>
                    <p className="text-[#1c1917] md:text-sm sm:text-xs font-semibold">
                        17
                    </p>
                </motion.div>
                <div className="flex relative">

                    <motion.div
                        variants={item}
                        className="md:absolute flex bg-white minsm:max-w-[22.7rem] justify-center  ml-4 py-4 sm:py-3  items-center space-x-3 md:space-x-2 sm:space-x-0 md:-left-28   rounded-xl md:w-[16rem] w-[24rem] sm:w-[15rem]  "
                    >
                        <Vector color="green" className="sm:h-3 "/>
                        <p className="md:text-xs text-sm font-semibold ">
                            {t<string>("success1")}
                        </p>
                    </motion.div>
                    <motion.div

                        variants={item}
                        className="absolute -left-9  md:hidden top-6"
                    >
                        <img alt="" src={leftb}/>
                    </motion.div>
                </div>
                <div className=" flex space-x-6 relative ">
                    <motion.div
                        variants={item}
                        className=" flex bg-white justify-center absolute -left-24  top-4 sm:-left-32 sm:top-16 rounded-xl space-x-2 w-[15rem] py-4 sm:py-2 sm:w-[10rem]  md:-left-14 md:top-8  "
                    >
                        <ForJob/>
                        <p className="font-semibold md:text-sm sm:text-xs">
                            {t<string>("applicants")}
                        </p>
                    </motion.div>
                    <motion.div
                        variants={item}
                        className="absolute minmd:hidden top-1  -left-20 sm:w-6 sm:top-10"
                    >
                        <img className="  rotate-90" alt="" src={leftl}/>
                    </motion.div>
                    <motion.div
                        variants={item}
                        className="absolute top-10 left-32 sm:left-24 sm:top-11 sm:w-6 sm:left-5  sm:top-20"
                    >
                        <img className="  " alt="" src={leftl}/>
                    </motion.div>

                    <motion.div
                        variants={item}
                        className=" flex bg-[#f97316] text-white text-center absolute justify-center sm:text-xs rounded-2xl left-44 z-10 md:top-8 md:left-44 top-4 md:w-[8rem] w-[10rem] sm:w-[6rem] py-2 px-8 sm:left-12 sm:top-16"
                    >
                        <p className=""> {t<string>("qualifications")}</p>
                    </motion.div>
                    <motion.div
                        variants={item}
                        className="absolute -right-9 top-11 md:-right-20 md:-rotate-45 sm:w-12  md:top-3 sm:hidden "
                    >
                        <img className=" " src={rotated} alt=""/>
                    </motion.div>
                    <motion.div
                        variants={item}
                        className="-right-9 top-14 absolute minsm:hidden   "
                    >
                        <img className=" " src={vectorr} alt=""/>
                    </motion.div>
                    <motion.div
                        variants={item}
                        className="absolute minmd:hidden right-32 sm:top-36 sm:-left-4 "
                    >
                        <img className=" " src={Cardvect} alt=""/>
                    </motion.div>
                </div>


                <motion.div
                    variants={item}
                    className="shadow-md shadow-slate-100 rounded-[20px]  w-[16rem] sm:max-w-[11.5rem]   bg-[#3f3f46] drop-shadow-xl absolute right-6 sm:-right-10 -bottom-12 md:-right-24 sm:-right-2 sm:-bottom-20"
                >
                    <div className="space-y-4 sm:space-y-2 p-4 sm:px-2">
                        <div className="flex items-center space-x-2">
                            <Vector className="sm:h-3 sm:w-3" color="white"/>
                            <p className="text-white text-sm sm:text-xs sm:text-[10px]">
                                {t<string>("carditem1")}
                            </p>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Vector className="sm:h-3 sm:w-3" color="white "/>
                            <p className="text-white text-sm sm:text-xs sm:text-[10px]">
                                {t<string>("carditem2")}
                            </p>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Vector className=" sm:h-3 sm:w-3" color="white"/>
                            <p className="text-white text-sm sm:text-xs sm:text-[10px]">
                                {t<string>('carditem3')}
                            </p>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Vector className="sm:h-3 sm:w-3" color="#94a3b8"/>

                            <p className="text-[#94a3b8] text-sm sm:text-[10px]">
                                {" "}
                                {t<string>('carditem4')}
                            </p>
                        </div>
                        <div className="flex items-center space-x-2">
                            <Vector className="sm:h-3 sm:w-3" color="#94a3b8"/>
                            <p className="text-[#94a3b8] text-sm sm:text-xs sm:text-[10px]">
                                {" "}
                                {t<string>('carditem5')}
                            </p>
                        </div>
                    </div>
                </motion.div>

                <motion.div
                    variants={item}
                    className="mt-6 absolute bottom-16 left-10 md:hidden "
                >
                    <img className="rotate-180 w-12" src={leftl} alt=""/>
                </motion.div>


                <motion.div
                    variants={item}
                    className="flex flex-row  absolute -bottom-12 -left-[18rem] sm:-left-[8rem] sm:-bottom-40 md:-left-10 md:-bottom-28"
                >
                    <motion.div variants={item} className=" space-y-1 pt-4">
                        <Star/>
                        <Star className="h-3 w-4"/>
                        <Star className="h-2 w-4"/>
                    </motion.div>
                    <div
                        className="outline outline-[#e2e8f0] outline-1 shadow-md shadow-slate-100 rounded-[20px] sm:max-w-[10rem] w-[19rem]  sm:h-[13rem] bg-white h-[14rem] drop-shadow-xl   ">
                        <div>
                            <div>
                                <h5 className="text-[#FF5601] font-semibold ml-4 pt-4 text-sm sm:text-xs">
                                    {t<string>("top")}
                                </h5>
                                <div className="mt-2 h-px mb-4 mx-2 bg-[#d1d5db] "/>
                                <div className="space-y-4 sm:space-y-2">
                                    <div className="flex items-center pl-6 sm:pl-4 space-x-4 sm:space-x-2">
                                        <img className="  h-10 w-10 " src={avatar} alt=""/>
                                        <div>
                                            <p className=" text-sm font-semibold  md:text-xs ">
                                                {" "}
                                                Cris Walker
                                            </p>
                                            <p className="text-sm  text-[#9B9B9B] md:text-xs ">
                                                Austria
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-center pl-6 sm:pl-4 space-x-4 sm:space-x-2">
                                        <img className="h-10 w-10  " src={seconavatar} alt=""/>
                                        <div>
                                            <p className="text-sm font-semibold  md:text-xs ">
                                                {" "}
                                                Mayer Permiter
                                            </p>
                                            <p className="text-sm  text-[#9B9B9B] md:text-xs ">
                                                Germany
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex items-center pl-6 sm:pl-4 space-x-4 sm:space-x-2">
                                        <img className=" h-10 w-10  " src={thirdavatar} alt=""/>
                                        <div>
                                            <p className="text-sm font-semibold md:text-xs ">
                                                {" "}
                                                Emma Muro
                                            </p>
                                            <p className="text-sm  text-[#9B9B9B] md:text-xs ">
                                                France
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </motion.div>
        </motion.div>
    );
};

export default Cards;
