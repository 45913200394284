import React, {useState} from "react"
import {Swiper, SwiperSlide} from "swiper/react"
import Arrow from "../../../assets/img/Arrow"

const Slider = ({images}: { images: string[] }) => {
    const [swiper, setSwiper] = useState<any>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    console.log(activeIndex);

    return (
        <div className="mt-6" id="image-slider">
            <div className="sm:px-0 flex relative ">
                <Swiper
                    loop
                    slidesPerView={2.3}
                    spaceBetween={20}
                    breakpoints={{
                        640: {
                            slidesPerView: 2.5,
                        },
                        // when window width is >= 768px
                        1025: {
                            slidesPerView: 4.5,
                        },
                    }}
                    onSwiper={(s) => setSwiper(s)}
                >
                    {images.map((item: string, i: number) => (
                        <SwiperSlide key={i} className="flex justify-center last:blur-sm">
                            <img
                                className="aspect-square transition-all duration-300  rounded-xl w-64 object-cover "
                                src={item}
                                alt=""
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <Arrow
                    onClick={() => swiper.slideNext()}
                    color="white"
                    className="w-2 h-4 absolute top-1/2 -translate-y-1/2 cursor-pointer z-50 right-4 rotate-180 lg:hidden"
                />
                <Arrow
                    onClick={() => swiper.slidePrev()}
                    color="white"
                    className="w-2 h-4 absolute top-1/2 -translate-y-1/2 cursor-pointer z-50 left-4 lg:hidden"
                />
            </div>
        </div>
    );
};

export default Slider;
