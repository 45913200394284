const LinkedinIco = ({className}: { className?: string }) => {
    return (
        <svg className={className} width="48" height="48" viewBox="0 0 48 48" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M42.1356 0C45.3723 0 48 2.62781 48 5.86444V42.1356C48 45.3722 45.3722 48 42.1356 48H5.86444C2.62781 48 0 45.3722 0 42.1356V5.86444C0 2.62781 2.62772 0 5.86444 0L42.1356 0ZM15.0432 39.6823V18.5213H8.00822V39.6823H15.0432ZM40.4062 39.6823V27.5474C40.4062 21.0474 36.9358 18.0237 32.308 18.0237C28.5764 18.0237 26.9048 20.076 25.9689 21.5174V18.5213H18.9357C19.029 20.5071 18.9357 39.6823 18.9357 39.6823H25.9688V27.8646C25.9688 27.2319 26.0144 26.5997 26.2008 26.1475C26.7083 24.8843 27.8664 23.5756 29.8096 23.5756C32.3535 23.5756 33.3727 25.5169 33.3727 28.3603V39.6823H40.4062ZM11.5732 8.31769C9.16631 8.31769 7.59375 9.90009 7.59375 11.9741C7.59375 14.0046 9.1185 15.6306 11.4802 15.6306H11.5255C13.9785 15.6306 15.5054 14.0046 15.5054 11.9741C15.4599 9.903 13.9828 8.32219 11.5732 8.31769Z"
                  fill="black"/>
        </svg>

    )
}

export default LinkedinIco