import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import JobsCard from '../JobsCard/JobsCard'
import {useLocation} from 'react-router-dom'
import axios from "axios";
import JobSlider from '../JobsCard/JobSlider'

const PopularJobs = () => {

    const {t} = useTranslation()
    const location = useLocation()

    const breakpoint = 820
    const [width, setWidth] = React.useState(window.innerWidth);
    const [oportunityList, setOportunityList]: any = useState([]);
    const getRandomArray = (array: any[]) => {
        const shuffled = array.sort(() => 0.5 - Math.random());

        return shuffled.slice(0, 3);
    };

    useEffect(() => {
        axios
            .get("/data/activities.json")
            .then((res) => {
                setOportunityList(getRandomArray(res.data));
            })
            .catch(() => {
            });
        window.addEventListener("resize", () => setWidth(window.innerWidth))
    }, []);

    return (

        width < breakpoint ? <JobSlider oportunityList={oportunityList}/> :
            <section id='jobs'
                     className='text-center mt-40 flex flex-col justify-center items-center mx-auto  sm:pb-5 sm:px-4 mx-6  '>
                <div className='max-w-4xl mx-auto'>
                    <h3 className='font-bold text-5xl sm:text-2xl sm:leading-9 leading-[63px] my-8  text-[#1B2A3E]'>
                        {location.pathname === '/candidates' ? t<string>("thirdTitle") : t<string>("thirdcomp")}
                    </h3>
                    <p className='text-md sm:text-xs sm:leading-[22px] text-[#6b7280] leading-8'>{t<string>('thirdText')}</p>
                </div>
                <div className='flex'>
                    <JobsCard oportunityList={oportunityList}/>
                </div>

            </section>
    )
}

export default PopularJobs