import React from 'react'
import {useTranslation} from 'react-i18next'

import Redev360Img from '../../../../assets/pictures/Redev360Img'


const GetStarted = () => {
    const {t} = useTranslation()

    return (

        <section id='socialmedia' className='h-96 md:h-[36rem] mb-20 sm:mb-32 bg-[#eef2ff] mt-16 mx-auto p-3 '>
            <div className='flex md:flex-col   max-w-7xl mx-auto minlg:space-x-[10%] '>
                <div className=' space-y-10 sm:space-y-6 minlg:ml-20 md:ml-6 mt-16 minlg:w-[35%]'>
                    <div><h1
                        className='  text-3xl md:text-3xl leading-[53px] font-semibold tracking-wide md:text-2xl '>{t<string>('Redev360title')}</h1>
                    </div>
                    <a href='https://redev-dashboard-demo.mimteam.net' target={"_blank"}>
                        <button
                            type="submit"
                            className=" bg-[#3869F1] rounded-[8px] md:rounded-[4px] text-white   text-xl px-8  py-2 "
                        > {t<string>('get started')}</button>
                    </a>
                </div>
                <div className='flex justify-center minlg:w-[50%]'><Redev360Img
                    className='lg:w-96 minlg:w-[35rem] aspect-video'/>
                </div>
            </div>
        </section>
    )
}

export default GetStarted
