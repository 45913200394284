import React from 'react'
import ReactPlayer from 'react-player'
import CloseSquare from "../../assets/icons/CloseSquare";


const PlayerModal = ({show, closeModalFn,videoUrl}: { show: boolean, closeModalFn: () => void,videoUrl:string }) => {
    return (

        !show ? null :
            <div
                className=" flex justify-center items-center overflow-y-auto overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur backdrop-brightness-50">
                <div className="relative  my-6 mx-auto w-full sm:w-full sm:min-h-[100vh] max-w-[90vw] max-h-[98vh]">
                    <div
                        className={`border-0 rounded-lg sm:rounded-none shadow-lg relative flex flex-col items-center md:justify-center w-full bg-transparent sm:w-full sm:min-h-[100vh] outline-none focus:outline-none min-h-[400px]`}>
                        <div
                            className='relative bg-black rounded-[4px] w-full flex items-center justify-center  md:w-screen'>
                            <CloseSquare className='absolute right-2 top-2 cursor-pointer z-30' onClick={closeModalFn}/>
                            <ReactPlayer url={videoUrl} width={'100%'} height={'90%'} controls playing
                                         pip={false}/>
                        </div>
                    </div>
                </div>
            </div>

    )
}

export default PlayerModal