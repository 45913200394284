import React, {useEffect, useState} from "react"
import axios from "axios"
import {useTranslation} from "react-i18next";
import Arrow from "../../../../assets/img/Arrow"
import {Swiper, SwiperSlide} from "swiper/react"
import CreateMAIcon from "../../../../assets/icons/CreateMAIcon"
import EarnMoneyIcon from "../../../../assets/icons/EarnMoneyIcon"
import GrowSkillsIcon from "../../../../assets/icons/GrowSkillsIcon"
import InstructorIcon from "../../../../assets/icons/InstructorIcon"

const MasterClass = () => {
    const [swiper, setSwiper] = useState<any>(null)
    const [courseData, setCourseData] = useState([])
    const [blueArr, blueArrsetData] = useState([0])
    const [data, setData] = useState([])
    const [slideIndex, setSlideIndex] = useState<number>(0)
    const {t} = useTranslation()

    useEffect(() => {
        axios
            .get("/data/cards.json")
            .then((res) => {
                setData(res.data);
            })
            .catch(() => {
            });
    }, []);

    useEffect(() => {
        axios
            .get("/data/courses.json")
            .then((res) => {
                setCourseData(res.data)
            })
            .catch(() => {
            })
    }, [])
    console.log(slideIndex);


    const getImgByName = (name: string, color: string) => {
        if (name == "CreateMAIcon") {
            return <CreateMAIcon color={color}/>
        } else if (name == "EarnMoneyIcon") {
            return <InstructorIcon color={color}/>
        } else if (name == "GrowSkillsIcon") {
            return <EarnMoneyIcon color={color}/>
        } else {
            return <GrowSkillsIcon color={color}/>
        }
    }
    return (
        <section id="masterclass" className="max-w-7xl mx-auto ">
            <div className="relative md:mx-6 mt-[7.5rem] w-full sm:mt-10 my-6  lg:mb-14 ">
                <div className="flex flex-col space-y-4 sm:pr-4 sm:mt-40 relative">
                    <div className="flex minlg:ml-40 md:ml-2  lg:ml-20">
                        <div
                            className=" text-4xl  md:text-2xl sm:font-bold font-semibold   sm:text-xl w-[79%] sm:leading-9	sm:w-[100%]  text-[#1B2A3E]">
                            {t<string>("coursetitle1")}
                            <span
                                className="text-[#3869F1] "> {t<string>("coursetitle2")}</span> {t<string>("coursetitle3")}
                        </div>
                        <div>
                            <hr className="w-[15rem] sm:hidden h-[4px] mt-6  bg-black "/>
                        </div>
                    </div>
                    <div className="sm:text-xs minlg:ml-40 sm:leading-6 md:ml-2 lg:ml-20 sm:mt-6">
                        {t<string>("coursetext")}
                    </div>
                </div>
                <div className="grid grid-cols-4 px-24 md:px-2 w-full  md:mr-4 sm:pr-16 mt-10 sm:mt-6">
                    {courseData.map((dataItem: any, i: number) => (
                        <div
                            key={i}
                            className="col-span-1 space-y-4 flex flex-col items-center relative"
                        >
                            <div className="">
                                {getImgByName(dataItem.image, slideIndex >= i ? "#2563eb" : "#737373")}
                            </div>
                            <div
                                className={`h-[3px]  bg-[#737373] absolute -left-32 right-32 sm:-left-10 sm:right-10 sm:bottom-[5%] bottom-[45%] lg:bottom-[55%] lg:-left-1/2 lg:w-full z-0 ${
                                    slideIndex >= i ? "bg-[#2563eb]" : ""
                                }`}
                            />
                            <span
                                className={`inline-flex rounded-full h-3 w-3 z-1  block  sm:w-2 sm:h-2 bg-[#737373] ${
                                    slideIndex >= i ? "bg-[#2563eb]" : ""
                                }`}>
                            </span>
                            <div
                                className={` text-md  md:hidden text-[#737373] ${
                                    slideIndex === i ? "text-[#2563eb]" : ""
                                }`}
                            >
                                {dataItem.title}
                            </div>
                        </div>
                    ))}
                </div>
                {data.length > 0 ? (
                    <Swiper
                        loop
                        slidesPerView={1}
                        onSwiper={(s) => setSwiper(s)}
                        onSlideChange={(e) => {
                            setSlideIndex(e.realIndex);

                        }}
                    >
                        {data.map((dataItem: any, i: number) => (
                            <SwiperSlide
                                key={i}
                                className="flex flex-row sm:flex-col justify-center  "
                            >
                                <div className="flex flex-col items-center basis-[52%] mt-10 sm:mt-6">
                                    <div className="space-y-6 ">
                                        <div className="text-3xl md:text-2xl sm:text-xl font-bold text-[#1B2A3E] ">
                                            {t<string>(dataItem.title)}
                                        </div>
                                        <div
                                            className=" md:text-sm md:leading-6  w-[80%] sm:w-[100%]  text-2xl   text-[#333333]">
                                            {t<string>(dataItem.subtitle)}
                                        </div>
                                        <div
                                            className="  sm:hidden w-[90%] md:text-sm md:leading-[22px] minmd:text-md text-[#6b7280] leading-8 md:text-left text-justify">
                                            {t<string>(dataItem.text)} <span
                                            className='font-bold '> {t<string>(dataItem.textbold)}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <img
                                        className="rounded-lg scale-[90%] sm:hidden "
                                        src={dataItem.imgUrl}
                                        alt=""
                                    />
                                    <div className="flex minsm:hidden pr-6">
                                        <div className="minsm:basis-1/2 mt-4 ">
                                            <img
                                                className="rounded-lg flex minlg:scale-125  "
                                                src={dataItem.imgUrl}
                                                alt=""
                                            /></div>
                                        <div
                                            className="  minsm:hidden w-[86%] text-xs mt-16 ml-2 text-[#6b7280] leading-5 text-left pr-4 ">
                                            {t<string>(dataItem.text)}
                                            <span className='font-bold'>{t<string>(dataItem.textbold)}</span>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    ""
                )}
                <div className="flex gap-x-6 sm:gap-x-2 gap-x-8 sm:hidden absolute left-40 bottom-0 z-30 lg:hidden">
                    <button
                        onClick={() => swiper.slidePrev()}
                        type="button"
                        className={`transition-colors rounded-md duration-500 group bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] sm:h-[40px] sm:w-[40px]   hover:bg-[#3869F1]`}
                    >
                        <Arrow className="w-6 h-6 fill-[#3869F1] group-hover:fill-white"/>
                    </button>

                    <button
                        onClick={() => swiper.slideNext()}
                        type="button"
                        className={`transition-colors rounded-md duration-500 group bg-[#DAE4FB] flex justify-center items-center w-[46px] h-[46px] sm:h-[40px] sm:w-[40px]  hover:bg-[#3869F1]`}
                    >
                        <Arrow className="w-6 h-6 rotate-180 fill-[#3869F1] group-hover:fill-white"/>
                    </button>
                </div>
            </div>
        </section>
    )
}
export default MasterClass
