import {MouseEventHandler} from "react";

const OrangePlayIcon = ({className, onClick}: { className?: string, onClick?: MouseEventHandler }) => {
    return (
        <svg onClick={onClick} className={className} width="92" height="92" viewBox="0 0 92 92" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.9" filter="url(#filter0_d_1786_35)">
                <mask id="path-1-outside-1_1786_35" maskUnits="userSpaceOnUse" x="8" y="4" width="76" height="76"
                      fill="black">
                    <rect fill="white" x="8" y="4" width="76" height="76"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M46 78C65.8823 78 82 61.8823 82 42C82 22.1177 65.8823 6 46 6C26.1177 6 10 22.1177 10 42C10 61.8823 26.1177 78 46 78ZM35.8107 31.6882V52.8249C35.8107 55.2755 38.4145 56.8071 40.4057 55.735L58.7854 45.1667C60.9297 43.9413 60.9297 40.7249 58.7854 39.4996L40.4057 28.9313C38.4145 27.7059 35.8107 29.2376 35.8107 31.6882Z"/>
                </mask>
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M46 78C65.8823 78 82 61.8823 82 42C82 22.1177 65.8823 6 46 6C26.1177 6 10 22.1177 10 42C10 61.8823 26.1177 78 46 78ZM35.8107 31.6882V52.8249C35.8107 55.2755 38.4145 56.8071 40.4057 55.735L58.7854 45.1667C60.9297 43.9413 60.9297 40.7249 58.7854 39.4996L40.4057 28.9313C38.4145 27.7059 35.8107 29.2376 35.8107 31.6882Z"
                      fill="#FF6F3D" shapeRendering="crispEdges"/>
                <path
                    d="M40.4057 55.735L41.3539 57.4959L41.3784 57.4827L41.4026 57.4688L40.4057 55.735ZM58.7854 45.1667L57.7931 43.4302L57.7884 43.4328L58.7854 45.1667ZM58.7854 39.4996L57.7884 41.2334L57.7931 41.2361L58.7854 39.4996ZM40.4057 28.9313L39.3575 30.6346L39.3829 30.6502L39.4087 30.6651L40.4057 28.9313ZM80 42C80 60.7777 64.7777 76 46 76V80C66.9868 80 84 62.9868 84 42H80ZM46 8C64.7777 8 80 23.2223 80 42H84C84 21.0132 66.9868 4 46 4V8ZM12 42C12 23.2223 27.2223 8 46 8V4C25.0132 4 8 21.0132 8 42H12ZM46 76C27.2223 76 12 60.7777 12 42H8C8 62.9868 25.0132 80 46 80V76ZM37.8107 52.8249V31.6882H33.8107V52.8249H37.8107ZM39.4575 53.974C38.8645 54.2933 37.8107 53.8374 37.8107 52.8249H33.8107C33.8107 56.7136 37.9646 59.3209 41.3539 57.4959L39.4575 53.974ZM57.7884 43.4328L39.4087 54.0012L41.4026 57.4688L59.7823 46.9005L57.7884 43.4328ZM57.7931 41.2361C58.1694 41.4511 58.3936 41.8525 58.3936 42.3331C58.3936 42.8137 58.1694 43.2152 57.7931 43.4302L59.7776 46.9031C63.2656 44.91 63.2656 39.7562 59.7776 37.7631L57.7931 41.2361ZM39.4087 30.6651L57.7884 41.2334L59.7823 37.7658L41.4026 27.1974L39.4087 30.6651ZM37.8107 31.6882C37.8107 31.2096 38.0538 30.8468 38.3851 30.6495C38.715 30.4529 39.062 30.4528 39.3575 30.6346L41.4539 27.2279C38.0022 25.1038 33.8107 27.8256 33.8107 31.6882H37.8107Z"
                    fill="white" fillOpacity="0.8" mask="url(#path-1-outside-1_1786_35)"/>
            </g>
            <defs>
                <filter id="filter0_d_1786_35" x="0" y="0" width="92" height="92" filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                   result="hardAlpha"/>
                    <feOffset dy="4"/>
                    <feGaussianBlur stdDeviation="4"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1786_35"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1786_35" result="shape"/>
                </filter>
            </defs>
        </svg>

    )
}

export default OrangePlayIcon