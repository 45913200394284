import React from 'react'

const Star = ({ className }: { className?: string }) => {
  return (
    <svg className={className}  width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.47348 5.14618C5.47348 5.14618 2.44621 5.49769 0.426743 5.7327C0.244723 5.7558 0.0837795 5.88335 0.0234256 6.07719C-0.0369284 6.27102 0.0234255 6.47289 0.157545 6.60044C1.65777 8.03563 3.91194 10.1849 3.91194 10.1849C3.91003 10.1849 3.29308 13.3114 2.88305 15.3973C2.84856 15.5862 2.91371 15.786 3.07082 15.9055C3.22697 16.0251 3.42815 16.0271 3.58622 15.9337C5.35277 14.8811 7.99877 13.2983 7.99877 13.2983C7.99877 13.2983 10.6457 14.8811 12.4094 15.9347C12.5703 16.0271 12.7715 16.0251 12.9277 15.9055C13.0848 15.786 13.1499 15.5862 13.1145 15.3984C12.7045 13.3114 12.0885 10.1849 12.0885 10.1849C12.0885 10.1849 14.3426 8.03563 15.8429 6.60346C15.977 6.47189 16.0364 6.27002 15.977 6.07719C15.9176 5.88436 15.7566 5.75681 15.5746 5.73471C13.5552 5.49769 10.5269 5.14618 10.5269 5.14618C10.5269 5.14618 9.27195 2.23563 8.43562 0.295273C8.35706 0.121524 8.19228 0 7.99877 0C7.80525 0 7.63952 0.122528 7.56479 0.295273C6.7275 2.23563 5.47348 5.14618 5.47348 5.14618Z" fill="#FF6F3D"/>
    </svg>
    
  )
}

export default Star